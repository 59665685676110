import React from 'react';
import {getUsers} from "../../../api/api";
import AutoSuggest from "./AutoSuggest";
import {STATE} from "./UserStateAutoSuggest";

class UserAutoSuggest extends React.Component {
    static defaultProps = {
        onDataFetch: (q) =>
            getUsers(q, 0, 15,null, null, true)
                .then(value => value.content)
    }

    render() {
        return (
            <AutoSuggest
                label="User"
                fetchOptions={(q) => this.props.onDataFetch(q)}
                renderOption={(user) => ({
                    label: `${user.FullName} - ${user.Name} ${user.State === STATE.INACTIVE ? '(inactive)' : ''}`,
                    value: user
                })}
                optionIdentifier={(user) => user.Name}
                debounceTimeout={300}
                {...this.props}
            />
        );
    }
}

export default UserAutoSuggest;
