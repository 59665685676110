import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "App";

import MenuButton from "../components/menuItem/MenuButton";
import CenteredView from "../centeredView/CenteredView";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

function LoginPage({ onLogin }) {
    const userContext = useContext(UserContext);

    return (
        <>
            {userContext ? (
                <Redirect to="/" />
            ) : (
                <CenteredView>
                    Please
                    <MenuButton name="Login" icon={<ExitToAppIcon />} handler={onLogin} />
                    to see this page.
                </CenteredView>
            )}
        </>
    );
}

export default LoginPage;
