import React, { useState, useEffect, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { UserContext } from "App";

const Changelog = () => {
    const [changelogText, setChangelogText] = useState("");
    const [changelogPermissionText, setChangelogPermissionText] = useState("");

    const userContext = useContext(UserContext);

    const userPermissions = [];

    for (const property in userContext.Permissions) {
        if (userContext.Permissions[property]) userPermissions.push(property);
    }

    useEffect(() => {
        const fetchChangelog = async () => {
            try {
                const response = await fetch("changelog/changelog_1.0.md");
                const text = await response.text();
                setChangelogText(text);
            } catch (error) {
                console.error("Error fetching changelog: ", error);
            }
        };

        fetchChangelog();
        checkChangelogPermissions();
    }, [changelogText]);

    const checkChangelogPermissions = () => {
        let textWithPermissions = "";
        let start = 0;
        let end = changelogText.indexOf("#!", start);
        let addedText = "";
        let permissions = [];

        textWithPermissions += changelogText.slice(start, end);
        start = end;

        while ((end = changelogText.indexOf("#!", start))) {
            permissions = changelogText
                .slice(end + 3, changelogText.indexOf(")", end))
                .split(",")
                .map((permission) => permission);
            start = end + 3;
            end = changelogText.indexOf("\n", start);
            addedText = changelogText.slice(end, changelogText.indexOf("#!", end));

            if (permissions.find((permission) => permission === "*")) {
                textWithPermissions += addedText;
            } else if (permissions.some((v) => userPermissions.indexOf(v) !== -1)) {
                textWithPermissions += addedText;
            }
            start = changelogText.indexOf("#!", end);
            setChangelogPermissionText(textWithPermissions);
            if (end === -1 || start === -1) return 0;
        }
    };

    return (
        <div>
            <h1>Release notes</h1>
            <ReactMarkdown>{changelogPermissionText}</ReactMarkdown>
        </div>
    );
};

export default Changelog;
