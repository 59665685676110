import React, { Fragment, useState, useContext } from "react";
import { UserContext } from "App";
import { createFAQCategory, updateFAQCategoryPositions } from "api/api";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FAQCategoryForm from "./FAQCategoryForm";
import FAQSearch from "./FAQSearch";
import FAQCategoryPanel from "./FAQCategoryPanel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
    categoryContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gridGap: theme.spacing(3),
        maxWidth: "850px",
    },
    categoryPanel: {
        display: "flex",
    },
    centerBox: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "1rem 0",
    },
}));

export default function FAQ({ categories, maxPriority, setFetchTrigger }) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [openCategoryForm, setOpenCategoryForm] = useState(false);
    const [positioningMode, setPositioningMode] = useState(false);
    const loading = categories === undefined;

    const createNewCategory = async (category) => {
        return createFAQCategory(category).then((newCategory) => {
            if (newCategory.id) {
                setFetchTrigger(true);
            }

            return newCategory;
        });
    };

    const handleDragEnd = ({ destination, source }) => {
        if (!destination) return;

        const [moved] = categories.splice(source.index, 1);

        updateFAQCategoryPositions({ ...moved, category_priority: destination.index + 1 }).then(
            () => {
                setFetchTrigger(true);
            }
        );
    };
    return (
        <Fragment>
            <FAQCategoryForm
                open={openCategoryForm}
                handleClose={() => {
                    setOpenCategoryForm(false);
                }}
                handleCreate={createNewCategory}
                total={maxPriority}
            />

            <Grid container spacing={3}>
                <Grid item container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} lg={4}>
                        <FAQSearch />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {userContext?.Permissions.GlobalAdministration && (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={positioningMode}
                                            onChange={() => {
                                                setPositioningMode(!positioningMode);
                                            }}
                                            name="positioningMode"
                                            color="primary"
                                        />
                                    }
                                    label="Positioning mode"
                                />
                            </Grid>
                            <Grid item>
                                {!positioningMode && (
                                    <Button
                                        onClick={() => {
                                            setOpenCategoryForm(true);
                                        }}
                                        variant={"contained"}
                                        color="primary">
                                        new category
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {userContext?.Permissions.GlobalAdministration && positioningMode && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <Grid
                                    container
                                    alignItems="center"
                                    direction="column"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}>
                                    {categories.map((category, index) => (
                                        <Draggable
                                            key={category.id}
                                            index={index}
                                            draggableId={category.id.toString()}>
                                            {(provided, snapshot) => (
                                                <Grid
                                                    item
                                                    key={category.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <FAQCategoryPanel
                                                        category={category}
                                                        positioningMode={positioningMode}
                                                    />
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}

                {!positioningMode && (
                    <Grid item xs={12}>
                        {loading ? (
                            <Box className={classes.centerBox}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container className={classes.categoryContainer}>
                                {categories.map((category) => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={category.id}
                                        className={classes.categoryPanel}>
                                        <FAQCategoryPanel
                                            category={category}
                                            positioningMode={positioningMode}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
}
