import React, { Fragment, useEffect, useState } from "react";
import { UserContext } from "App";
import { useParams, useHistory } from "react-router-dom";
import {
    removeFAQQuestion,
    getFAQCategoryQuestions,
    createFAQQuestion,
    editFAQQuestion,
    updateFAQPositions,
} from "api/api";

import FAQQuestion from "./FAQQuestion";
import FAQCategoryForm from "./FAQCategoryForm";
import { DEFAULT_FAQ_PAGE_SIZE } from "./FAQUtils";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Confirmation from "../components/confirmation/Confirmation";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FAQForm from "./FAQForm";
import Pagination from "@material-ui/lab/Pagination";
import { Typography } from "@material-ui/core";
import ImagePreview from "containers/components/imagePreview/ImagePreview";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
    categoryItem: {
        height: "5rem",
    },
    centerBox: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "1rem 0",
    },
    questionsContainer: {
        minHeight: "5rem",
        padding: "1rem 0",
    },
}));

export default function FAQSingleCategory({
    parentCategories,
    parentEditCategory,
    parentDeleteCategory,
    maxPriority,
}) {
    const { id } = useParams();
    const categoryId = parseInt(id);
    const classes = useStyles();
    const history = useHistory();
    const [category, setCategory] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openQuestionForm, setOpenQuestionForm] = useState(false);
    const [openCategoryForm, setOpenCategoryForm] = useState(false);
    const [openCategoryDelete, setOpenCategoryDelete] = useState(false);
    const [editedQuestion, setEditedQuestion] = useState(null);
    const [removedQuestion, setRemovedQuestion] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_FAQ_PAGE_SIZE);
    const [count, setCount] = useState(1);
    const [total, setTotal] = useState(null);

    const pageSizes = [8, 12, 16, 20];

    useEffect(() => {
        setLoading(true);

        getFAQCategoryQuestions(categoryId, { page: page - 1, size: pageSize }, true).then(
            (response) => {
                setTotal(response.total);
                setCount(Math.ceil(response.total / pageSize));
                setQuestions(response.content);
                setLoading(false);
            }
        );
    }, [categoryId, page, pageSize]);

    useEffect(() => {
        if (parentCategories?.length > 0) {
            const cat = parentCategories.find((cat) => {
                return cat.id === categoryId;
            });
            setCategory(cat);
            setPage(1);
        }
    }, [parentCategories, categoryId, id]);

    const fetchQuestions = () => {
        setLoading(true);

        getFAQCategoryQuestions(categoryId, { page: page - 1, size: pageSize }, true).then(
            (response) => {
                setTotal(response.total);
                setCount(Math.ceil(response.total / pageSize));
                setQuestions(response.content);
                setLoading(false);
            }
        );
    };

    const handleQuestionCreateOpen = () => {
        setEditedQuestion(null);
        setOpenQuestionForm(true);
    };

    const handleQuestionEditOpen = (item) => {
        setEditedQuestion(item);
        setOpenQuestionForm(true);
    };

    const editQuestion = async (question) => {
        return editFAQQuestion(question.id, question).then(() => {
            fetchQuestions();
            setEditedQuestion(null);
        });
    };

    const createQuestion = async (newQuestion) => {
        return createFAQQuestion(newQuestion).then(() => {
            fetchQuestions();
        });
    };

    const deleteQuestion = async (question) => {
        return removeFAQQuestion(question.id).then(() => {
            fetchQuestions();
        });
    };

    const editCategory = async (category) => {
        parentEditCategory(category);
    };

    const deleteCategory = async (removedCategory) => {
        parentDeleteCategory(removedCategory);
    };

    const updatePositions = async (questions) => {
        return updateFAQPositions(questions, false);
    };

    const handleChangePagination = (event, value) => {
        setPage(value);
    };

    const handleSelectChange = (event) => {
        history.push({ pathname: `/help/category/${event.target.value}` });
    };

    const handleSelectPageChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handlePreviewOpen = (image) => {
        setPreviewImage(image);
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
        setPreviewImage(null);
    };

    const handleDragEnd = ({ destination, source }) => {
        if (!destination) return;

        const [moved] = questions.splice(source.index, 1);
        updatePositions({
            ...moved,
            question_priority: destination.index + 1 + (page - 1) * pageSize,
        }).then(() => {
            fetchQuestions();
        });
    };

    return (
        <Fragment>
            <FAQForm
                open={openQuestionForm}
                question={editedQuestion}
                currentCategory={category}
                categories={parentCategories}
                categoriesLoading={loading}
                handleClose={() => setOpenQuestionForm(false)}
                handleCreate={createQuestion}
                handleEdit={editQuestion}
                total={total}
            />
            <FAQCategoryForm
                open={openCategoryForm}
                category={category}
                handleClose={() => {
                    setOpenCategoryForm(false);
                }}
                handleEdit={editCategory}
                total={maxPriority}
            />
            <Confirmation
                open={!!removedQuestion}
                onConfirm={() => deleteQuestion(removedQuestion)}
                onClose={() => setRemovedQuestion(null)}>
                Are you sure you want to remove{" "}
                {removedQuestion ? "question " + removedQuestion.question : "this question"}?
            </Confirmation>
            <Confirmation
                open={!!openCategoryDelete}
                onConfirm={() => deleteCategory(category)}
                onClose={() => setOpenCategoryDelete(false)}>
                Are you sure you want to remove{" "}
                {category ? "category " + category.name : "this category"}?
            </Confirmation>
            <ImagePreview open={previewOpen} onClose={handlePreviewClose} image={previewImage} />

            <Grid container spacing={2}>
                <Grid item container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <Select
                                native
                                value={categoryId}
                                onChange={handleSelectChange}
                                inputProps={{
                                    name: "Category",
                                }}>
                                {parentCategories?.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <UserContext.Consumer>
                        {(contextUser) => (
                            <>
                                {contextUser && contextUser.Permissions.GlobalAdministration ? (
                                    <>
                                        <Grid item>
                                            <Grid container justifyContent="flex-end" spacing={2}>
                                                {!loading && questions?.length === 0 && (
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                setOpenCategoryDelete(true);
                                                            }}
                                                            variant={"contained"}
                                                            color="primary">
                                                            remove category
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenCategoryForm(true);
                                                        }}
                                                        variant={"contained"}
                                                        color="primary">
                                                        edit category
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenQuestionForm(true);
                                                            handleQuestionCreateOpen();
                                                        }}
                                                        variant={"contained"}
                                                        color="primary">
                                                        new question
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {loading ? (
                                            <Box className={classes.centerBox}>
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <Grid
                                                                container
                                                                className={
                                                                    classes.questionsContainer
                                                                }
                                                                justifyContent="flex-end"
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}>
                                                                {questions?.map(
                                                                    (question, index) => (
                                                                        <Draggable
                                                                            key={question.id}
                                                                            index={index}
                                                                            draggableId={question.id.toString()}>
                                                                            {(
                                                                                provided,
                                                                                snapshot
                                                                            ) => (
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    key={
                                                                                        question.id
                                                                                    }
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <FAQQuestion
                                                                                        item={
                                                                                            question
                                                                                        }
                                                                                        setRemovedQuestion={
                                                                                            setRemovedQuestion
                                                                                        }
                                                                                        handleEditOpen={
                                                                                            handleQuestionEditOpen
                                                                                        }
                                                                                        onPreviewOpen={
                                                                                            handlePreviewOpen
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                )}
                                                                {provided.placeholder}
                                                            </Grid>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <Grid
                                                    container
                                                    className={classes.questionsContainer}
                                                    justifyContent="center">
                                                    {questions?.length > 0 ? (
                                                        <>
                                                            <FormControl>
                                                                <Select
                                                                    native
                                                                    value={pageSize}
                                                                    onChange={
                                                                        handleSelectPageChange
                                                                    }
                                                                    inputProps={{
                                                                        name: "PageSize",
                                                                    }}>
                                                                    {pageSizes?.map((size) => (
                                                                        <option
                                                                            key={size}
                                                                            value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <Pagination
                                                                count={count}
                                                                page={page}
                                                                onChange={handleChangePagination}
                                                                color="primary"
                                                            />
                                                        </>
                                                    ) : (
                                                        <Typography>
                                                            No questions in this category
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {loading ? (
                                            <Box className={classes.centerBox}>
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <>
                                                <Grid
                                                    container
                                                    className={classes.questionsContainer}
                                                    justifyContent="flex-end">
                                                    {questions?.map((question) => (
                                                        <Grid item xs={12} key={question.id}>
                                                            <FAQQuestion
                                                                item={question}
                                                                setRemovedQuestion={
                                                                    setRemovedQuestion
                                                                }
                                                                handleEditOpen={
                                                                    handleQuestionEditOpen
                                                                }
                                                                onPreviewOpen={handlePreviewOpen}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Grid
                                                    container
                                                    className={classes.questionsContainer}
                                                    justifyContent="center">
                                                    {questions?.length > 0 ? (
                                                        <>
                                                            <FormControl>
                                                                <Select
                                                                    native
                                                                    value={pageSize}
                                                                    onChange={
                                                                        handleSelectPageChange
                                                                    }
                                                                    inputProps={{
                                                                        name: "PageSize",
                                                                    }}>
                                                                    {pageSizes?.map((size) => (
                                                                        <option
                                                                            key={size}
                                                                            value={size}>
                                                                            {size}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <Pagination
                                                                count={count}
                                                                page={page}
                                                                onChange={handleChangePagination}
                                                                color="primary"
                                                            />
                                                        </>
                                                    ) : (
                                                        <Typography>
                                                            No questions in this category
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </UserContext.Consumer>
                </Grid>
            </Grid>
        </Fragment>
    );
}
