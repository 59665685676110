import React from "react";
import { Redirect, useParams } from "react-router-dom";

import MenuLink from "containers/components/menuItem/MenuLink";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LiveHelp from "@material-ui/icons/LiveHelp";

function ProtectedFAQCategory({ categories, redirectPath = "/unauthorized", children }) {
    const { id } = useParams();
    const categoryId = parseInt(id);

    if (categories === undefined) {
        return (
            <Box sx={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                <CircularProgress color="primary" />
            </Box>
        );
    }

    if (!categories.find((category) => category.id === categoryId)) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: {
                        unauthorized: true,
                        redirectBackElement: <MenuLink to="/help" name="FAQ" icon={<LiveHelp />} />,
                    },
                }}
            />
        );
    }

    return <>{children}</>;
}

export default ProtectedFAQCategory;
