import React from "react";
//
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
    },
}));

export default function Confirmation(props) {
    const { open, onClose, title, children, onConfirm, confirmLabel, closeLabel } = props;
    const classes = useStyles();

    const handleOnClick = () => {
        onConfirm().then(() => onClose(true));
    };
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={onClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleOnClick}>
                    {confirmLabel}
                </Button>
                <Button onClick={onClose}>{closeLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}

Confirmation.defaultProps = {
    title: "Confirmation",
    confirmLabel: "OK",
    closeLabel: "CANCEL",
};
