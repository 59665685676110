import React from "react";
import WomensDay from "./eventsTemplates/WomensDay";
import Valentines from "./eventsTemplates/Valentines";
import Easter from "./eventsTemplates/Easter";

export default function TemplatesFactory({ template }) {
    switch (template?.name?.toLowerCase()) {
        case "womens_day":
            return <WomensDay></WomensDay>;
        case "valentines":
            return <Valentines></Valentines>;
        case "easter":
            return <Easter></Easter>;
        default:
            return <></>;
    }
}
