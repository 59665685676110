import React, { Fragment, useEffect, useState } from "react";
import { deleteLocation, getLocations, restoreLocation } from "api/api";
import { UserContext } from "App";
import ResultTable from "containers/components/tables/ResultTable";

import withStyles from "@material-ui/styles/withStyles";
import Button from "@material-ui/core/Button";
import Confirmation from "../components/confirmation/Confirmation";
import CreateLocation from "./CreateLocation";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import EditIcon from "@material-ui/icons/Edit";
import EditLocation from "./EditLocation";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocationHoursDialog from "./LocationHoursDialog";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckHoursDialog from "./CheckHoursDialog";

const styles = (theme) => ({
    paper: {
        position: "absolute",
        width: "400px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none",
    },
    message: {
        padding: theme.spacing(1),
    },
    overflowX: {
        overflowX: "auto",
    },
});

const headers = [
    {
        key: "key",
        name: "Key",
        align: "left",
    },
    {
        key: "title",
        name: "Title",
        align: "left",
    },
    {
        key: "domain",
        name: "Domain",
        align: "left",
    },
    {
        key: "shortcut",
        name: "Shortcut",
        align: "left",
    },
    {
        key: "actions",
        name: "Actions",
        align: "center",
    },
];

function Locations() {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [locations, setLocations] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [editedLocation, setEditedLocation] = useState(null);
    const [removedLocation, setRemovedLocation] = useState(null);
    const [restoredLocation, setRestoredLocation] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);
    const [editHoursDialogOpen, setEditHoursDialogOpen] = useState(false);
    const [checkHoursDialogOpen, setCheckHoursDialogOpen] = useState(false);

    useEffect(() => {
        const fetchLocations = () => {
            getLocations(showDeleted, false).then((data) => {
                const temp = data.map((loc) => {
                    return createLocationRow(loc);
                });

                setLocations(temp ? temp : []);
            });
        };
        fetchLocations();
    }, [fetchTrigger, showDeleted, editHoursDialogOpen, checkHoursDialogOpen]);

    function createLocationRow(location) {
        return {
            id: location.id,
            key: location.key,
            title: location.title,
            domain: location.domain,
            shortcut: location.shortcut,
            isDeleted: location.isDeleted,
            actions: (
                <>
                    {!location.isDeleted ? (
                        <Grid container direction="row" wrap="nowrap" justifyContent="center">
                            <Grid item>
                                <Tooltip title="Edit">
                                    <IconButton
                                        color="primary"
                                        aria-label="edit location"
                                        onClick={() => {
                                            setEditedLocation(location);
                                            setOpenEditDialog(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Delete">
                                    <IconButton
                                        color="primary"
                                        aria-label="delete location"
                                        onClick={() => setRemovedLocation(location)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Set hours">
                                    <IconButton
                                        color="primary"
                                        aria-label="set hours"
                                        onClick={() => {
                                            setEditedLocation(location);
                                            setEditHoursDialogOpen(true);
                                        }}>
                                        <AccessTimeIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Set daily and quarterly hour checks">
                                    <IconButton
                                        color="primary"
                                        aria-label="set daily and quarterly hour checks"
                                        onClick={() => {
                                            setEditedLocation(location);
                                            setCheckHoursDialogOpen(true);
                                        }}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction="row" wrap="nowrap" justifyContent="center">
                            <Grid item>
                                <Tooltip title="Restore">
                                    <IconButton
                                        color="primary"
                                        aria-label="restore location"
                                        onClick={() => setRestoredLocation(location)}>
                                        <RestoreIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                </>
            ),
        };
    }

    const handleCreateClose = () => {
        setOpenCreateDialog(false);
    };

    const handleEditClose = () => {
        setOpenEditDialog(false);
    };

    const handleTrigger = () => {
        setFetchTrigger(!fetchTrigger);
    };

    const handleDelete = (id) => {
        return deleteLocation(id).then(handleTrigger);
    };

    const handleRestore = (id) => {
        return restoreLocation(id).then(handleTrigger);
    };

    const handleShowDeleted = () => {
        showDeleted === false ? setShowDeleted(null) : setShowDeleted(false);
    };

    const handleEditHoursDialogClose = () => {
        setEditHoursDialogOpen(false);
        setEditedLocation(null);
    };

    const handleCheckHoursDialogClose = () => {
        setCheckHoursDialogOpen(false);
        setEditedLocation(null);
    };

    return (
        <Fragment>
            <CheckHoursDialog
                location={editedLocation}
                open={checkHoursDialogOpen}
                handleClose={handleCheckHoursDialogClose}
            />
            <LocationHoursDialog
                location={editedLocation}
                open={editHoursDialogOpen}
                handleClose={handleEditHoursDialogClose}
            />
            <CreateLocation
                open={openCreateDialog}
                handleClose={handleCreateClose}
                handleSubmit={handleTrigger}
            />
            <EditLocation
                location={editedLocation}
                open={openEditDialog}
                handleClose={handleEditClose}
                handleSubmit={handleTrigger}
            />
            <Confirmation
                open={!!removedLocation}
                onConfirm={() => handleDelete(removedLocation.id)}
                onClose={() => setRemovedLocation(null)}>
                Are you sure you want to remove{" "}
                {removedLocation ? "location " + removedLocation.title : "this location"}?
            </Confirmation>
            <Confirmation
                open={!!restoredLocation}
                onConfirm={() => handleRestore(restoredLocation.id)}
                onClose={() => setRestoredLocation(null)}>
                Are you sure you want to restore{" "}
                {restoredLocation ? "location " + restoredLocation.title : "this location"}?
            </Confirmation>

            <h2 className="custom-title">Locations</h2>
            <Grid container spacing={2}>
                <Grid item container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <UserContext.Consumer>
                        {(contextUser) => (
                            <>
                                {contextUser && contextUser.Permissions.GlobalAdministration && (
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setOpenCreateDialog(true);
                                            }}
                                            variant={"contained"}
                                            color="primary">
                                            new location
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        )}
                    </UserContext.Consumer>
                </Grid>

                <Grid item container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <UserContext.Consumer>
                        {(contextUser) => (
                            <>
                                {contextUser && contextUser.Permissions.GlobalAdministration && (
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                handleShowDeleted();
                                            }}
                                            variant={"text"}
                                            color="primary">
                                            {showDeleted === false ? (
                                                <span>show deleted</span>
                                            ) : (
                                                <span>hide deleted</span>
                                            )}
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        )}
                    </UserContext.Consumer>
                </Grid>

                <Grid item xs={12}>
                    <ResultTable headers={headers} rows={locations} />
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default withStyles(styles, { withTheme: true })(Locations);
