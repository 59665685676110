import createTheme from "@material-ui/core/styles/createTheme";
import { THEME_PALETTE } from "./themeVariables";

export const darkTheme = createTheme({
    palette: {
        type: "dark",
        primary: { main: THEME_PALETTE.ttmsLightBlue },
        secondary: { main: THEME_PALETTE.ttmsLightBlue },
        text: {
            primary: "#d7d9e0",
        },
        error: {
            main: "#ff675c",
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                color: "#f3f5f6",
            },
        },
        MuiIconButton: {
            root: {
                color: "#f3f5f6",
            },
        },
    },
});
