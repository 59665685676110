import createTheme from "@material-ui/core/styles/createTheme";
import { THEME_PALETTE } from "./themeVariables";

export const lightTheme = createTheme({
    palette: {
        type: "light",

        primary: { main: THEME_PALETTE.ttmsBlue },
        secondary: { main: THEME_PALETTE.ttmsBlue },
        background: {
            default: "#ffffff",
        },
    },
});
