import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    clearButton: {
        color: theme.palette.background.default,
    },
    clearIcon: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function SearchBar({ query, setQuery, label, placeholder }) {
    const classes = useStyles();
    const clearQuery = () => {
        setQuery("");
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    return (
        <TextField
            id=""
            value={query}
            label={label}
            placeholder={placeholder}
            fullWidth
            variant="filled"
            onChange={handleChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {query.length ? (
                            <IconButton
                                aria-label="clear query input"
                                onClick={clearQuery}
                                className={classes.clearButton}>
                                <ClearIcon className={classes.clearIcon} />
                            </IconButton>
                        ) : (
                            <></>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
}
