import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchBar from "containers/components/searchBar/SearchBar";
import useDebounce from "../../../hooks/useDebounce";
//
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpIcon from "@material-ui/icons/Help";
import Fade from "@material-ui/core/Fade";
//

const useStyles = makeStyles(() => ({
    treeItemLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    selectAllButton: {
        minWidth: "9rem",
    },
    selectVisibleButton: {
        minWidth: "11rem",
    },
}));

const bfsSearch = (graph, targetId) => {
    const queue = [...graph];
    while (queue.length > 0) {
        const currNode = queue.shift();
        if (currNode.id === targetId) {
            return currNode;
        }
        if (currNode.children) {
            queue.push(...currNode.children);
        }
    }
    return []; // Target node not found
};

const getAllIds = (node, idList = []) => {
    idList.push(node.id);
    if (node.children) {
        node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
};

const countNodes = (items) => {
    let total = 0;
    items.forEach((item) => {
        total += item.children ? countNodes(item.children) : 0;
    });
    return total + items.length;
};

const filterItems = (items, projectQuery, taskQuery) => {
    let filteredProjects = items.filter((item) =>
        item.label.toLowerCase().includes(projectQuery.trim().toLowerCase())
    );

    if (taskQuery.length >= 3) {
        filteredProjects = filteredProjects
            .map((project) => {
                const filteredTasks = project.children.filter((task) =>
                    task.label.toLowerCase().includes(taskQuery.trim().toLowerCase())
                );

                return filteredTasks.length > 0
                    ? {...project, children: filteredTasks}
                    : null;
            })
            .filter(Boolean);
    }

    return filteredProjects;
};

export default function TreePicker({ items, selectedItems, setSelectedItems }) {
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
    const [searchQueryProject, setSearchQueryProject] = useState("");
    const [searchQueryTask, setSearchQueryTask] = useState("");
    const [lastSelectedId, setLastSelectedId] = useState(null);
    const [expandedNodes, setExpandedNodes] = useState(new Set());

    const debouncedSearchQueryProject = useDebounce(searchQueryProject, 500);
    const debouncedSearchQueryTask = useDebounce(searchQueryTask, 500);

    const totalNodeCount = useMemo(() => countNodes(items), [items]);

    const filteredItems = useMemo(() => {
        return filterItems(items, debouncedSearchQueryProject, debouncedSearchQueryTask);
    }, [debouncedSearchQueryProject, debouncedSearchQueryTask, items]);

    const getAllChildren = useCallback((id) => {
        const node = bfsSearch(items, id);
        return node ? getAllIds(node) : [];
    }, [items]);

    const getAllParents = useCallback((id, list = []) => {
        const node = bfsSearch(items, id);

        if (!!node?.parentId) {
            list.push(node.parentId);

            return getAllParents(node.parentId, list);
        }
        return list;
    }, [items]);

    const isAllChildrenChecked = useCallback((node, newSelectedNodes) => {
        const allChildren = getAllChildren(node.id);
        const nodeIndex = allChildren.indexOf(node.id);
        if (nodeIndex !== -1) allChildren.splice(nodeIndex, 1);
        return allChildren.every((nodeId) =>
            selectedItems.concat(newSelectedNodes).includes(nodeId)
        );
    }, [selectedItems, getAllChildren]);

    const handleNodeSelect = useCallback((event, nodeId) => {
        if (event) {
            event.stopPropagation();
        }

        const affectedNodes = [];

        if (event?.nativeEvent.shiftKey && lastSelectedId) {
            // find next select on same depth
            const parentIds = getAllParents(nodeId);
            const searchItems =
                parentIds.length > 0 ? bfsSearch(items, parentIds[0]).children : items;
            const selectedIndex = searchItems.findIndex((item) => nodeId === item.id);
            const previousIndex = searchItems.findIndex((item) => item.id === lastSelectedId);

            if (selectedIndex < 0 || previousIndex < 0) {
                return;
            }

            let i = selectedIndex > previousIndex ? previousIndex : selectedIndex;
            const max = selectedIndex > previousIndex ? selectedIndex : previousIndex;

            // mark selected nodes
            for (; i <= max; i++) {
                const children = getAllChildren(searchItems[i].id);
                affectedNodes.push(...children);
            }

            // mark parent nodes if all children were selected
            for (let i = 0; i < parentIds.length; i++) {
                if (isAllChildrenChecked(bfsSearch(items, parentIds[i]), affectedNodes)) {
                    affectedNodes.push(parentIds[i]);
                }
            }
        } else {
            const allChildren = getAllChildren(nodeId);
            const parents = getAllParents(nodeId);

            if (selectedItems.includes(nodeId)) {
                // items to uncheck
                affectedNodes.push(...[...allChildren, ...parents]);
            } else {
                // items to check
                affectedNodes.push(...allChildren);
                for (let i = 0; i < parents.length; i++) {
                    if (isAllChildrenChecked(bfsSearch(items, parents[i]), affectedNodes)) {
                        affectedNodes.push(parents[i]);
                    }
                }
            }
        }

        if (
            event
                ? event.target.checked
                : selectedItems.findIndex((itemId) => itemId === nodeId) < 0
        ) {
            // check all affected
            setSelectedItems((prevSelectedNodes) => {
                const newChecks = affectedNodes.filter(
                    (nodeId) => prevSelectedNodes.findIndex((oldNodeId) => oldNodeId === nodeId) < 0
                );

                return [...prevSelectedNodes, ...newChecks];
            });
        } else {
            // uncheck all affected
            setSelectedItems((prevSelectedNodes) =>
                prevSelectedNodes.filter((id) => !affectedNodes.includes(id))
            );
        }

        setLastSelectedId(nodeId);
    }, [getAllChildren, getAllParents, isAllChildrenChecked, items, lastSelectedId, selectedItems, setSelectedItems]);

    const handleExpandClick = (event) => {
        // prevent the click event from propagating to the checkbox
        event.stopPropagation();
    };

    const handleToggle = (event, nodeIds) => {
        setExpandedNodes(new Set(nodeIds));
    };

    const deselectSearchResults = () => {
        // Deselects visible search results. Without query deselects all items.
        if (filteredItems) {
            const filteredIds = filteredItems
                .map((item) => {
                    return getAllIds(item);
                })
                .flat(1);
            const remainingItems = selectedItems.filter((id) => filteredIds.indexOf(id) < 0);

            setSelectedItems(remainingItems);
        } else {
            setSelectedItems([]);
        }
    };

    const selectSearchResults = () => {
        // Selects visible search results. Without query selects all items.
        const toSelect = filteredItems ? filteredItems : items;
        const idBranches = toSelect.map((item) => getAllIds(item));
        const selectedUnion = [...new Set([...selectedItems, ...idBranches.flat()])];
        setSelectedItems(selectedUnion);
    };

    useEffect(() => {
        if (debouncedSearchQueryTask.length >= 3) {
            const newExpandedNodes = new Set();
            filteredItems.forEach((item) => {
                getAllParents(item.id).forEach((parentId) => {
                    newExpandedNodes.add(parentId);
                });
                newExpandedNodes.add(item.id);
            });
            setExpandedNodes(newExpandedNodes);
        } else {
            setExpandedNodes(new Set());
        }
    }, [debouncedSearchQueryTask, filteredItems]);

    const renderTree = useCallback(
        (node) => (
            <TreeItem
                key={node.id}
                nodeId={node.id}
                onClick={handleExpandClick}
                label={
                    <Box className={classes.treeItemLabel}>
                        <Typography>{node.label}</Typography>
                        <Checkbox
                            checked={selectedItems.indexOf(node.id) !== -1}
                            disableRipple
                            onClick={(event) => handleNodeSelect(event, node.id)}
                        />
                    </Box>
                }>
                {Array.isArray(node.children) ? node.children.map((child) => renderTree(child)) : null}
            </TreeItem>
        ),
        [selectedItems, handleNodeSelect]
    );

    return (
        <Grid container direction="column" spacing={3} className="picker-container">
            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <SearchBar
                        query={searchQueryProject}
                        setQuery={setSearchQueryProject}
                        label="Search Projects"
                        placeholder="Search Projects"
                    />
                </Grid>
                <Grid item xs={6}>
                    <SearchBar
                        query={searchQueryTask}
                        setQuery={setSearchQueryTask}
                        label="Search Tasks"
                        placeholder="Search Tasks"
                    />
                </Grid>
            </Grid>
            <Grid item>
                <TreeView
                    multiSelect
                    disableSelection
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    expanded={[...expandedNodes]}
                    onNodeToggle={handleToggle}
                    className="item-list">
                    {filteredItems.map((item) => renderTree(item))}
                </TreeView>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction={isSmUp ? "row" : "column"}
                    spacing={isSmUp ? 3 : 1}
                    justifyContent="flex-end"
                    alignItems={isSmUp ? "center" : "flex-end"}>
                    {(!!searchQueryProject || !!searchQueryTask) && (
                        <Fade in={!!searchQueryProject || !!searchQueryTask}>
                            <Grid item>
                                <Tooltip title="Only search results will be affected by Select/Deselect buttons">
                                    <HelpIcon/>
                                </Tooltip>
                            </Grid>
                        </Fade>
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={selectSearchResults}
                            disabled={selectedItems.length === totalNodeCount}
                            className={
                                searchQueryProject || searchQueryTask
                                    ? classes.selectVisibleButton
                                    : classes.selectAllButton
                            }>
                            {searchQueryProject || searchQueryTask ? "Select results" : "Select all"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={deselectSearchResults}
                            disabled={selectedItems.length === 0}
                            className={
                                searchQueryProject || searchQueryTask
                                    ? classes.selectVisibleButton
                                    : classes.selectAllButton
                            }>
                            {searchQueryProject || searchQueryTask ? "Deselect results" : "Deselect all"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
