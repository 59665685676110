import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EasterBackground from "../../../assets/easter/easter2.png";
import Title from "../../../assets/easter/rabbit4.png";
import Rabbit from "../../../assets/easter/rabbit5.png";

const useStyles = makeStyles((theme) => ({
    box: {
        height: "130px",
        width: "20px",
        marginBottom: "5px",
    },
    banner: {
        backgroundColor: theme.palette.background.default,
        backgroundImage: `url(${EasterBackground})`,
        backgroundRepeat: "repeat",
        height: "120px",
        width: "100%",
        backgroundSize: "230px",
        backgroundPosition: "bottom",
        zIndex: 9,
        marginBottom: "10px",
        position: "absolute",
        top: 70,
        right: 15,
        [theme.breakpoints.up("sm")]: {
            width: `calc(100vw - 240px)`,
            top: 0,
        },
    },
    title: {
        backgroundImage: `url(${Title})`,
        backgroundRepeat: "no-repeat",
        width: "170px",
        height: "130px",
        backgroundSize: "120px",
        backgroundPosition: "bottom left",
        zIndex: 10,
    },
    rabbit: {
        width: "60px",
        zIndex: 11,
        position: "absolute",
        bottom: 10,
        right: 10,
    },
}));

export default function Easter() {
    const classes = useStyles();

    return (
        <Fragment>
            <Grid container spacing={3} className={classes.box} justifyContent="flex-start"></Grid>
            <Grid container spacing={3} className={classes.banner} justifyContent="flex-start">
                <Box className={classes.title}></Box>
            </Grid>
            <img src={Rabbit} className={classes.rabbit} />
        </Fragment>
    );
}
