import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "App";

import MenuLink from "../menuItem/MenuLink";

import HomeIcon from "@material-ui/icons/Home";

function ProtectedRoute({
    isAllowed,
    redirectPath = "/unauthorized",
    redirectBackElement = <MenuLink to="/" name="Home" icon={<HomeIcon />} />,
    children,
}) {
    const userContext = useContext(UserContext);

    if (userContext === undefined) {
        // Prevent redirect before user information is fetched
        return null;
    }

    if (!userContext) {
        return <Redirect to="/login" />;
    }

    if (!isAllowed) {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: {
                        unauthorized: true,
                        redirectBackElement,
                    },
                }}
            />
        );
    }

    return <>{children}</>;
}

export default ProtectedRoute;
