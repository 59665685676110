import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
    },
    content: {},
}));

export default function FAQForm({
    open,
    question,
    categories,
    categoriesLoading,
    currentCategory,
    handleClose,
    handleEdit,
    handleCreate,
    total,
}) {
    const schema = yup
        .object({
            question: yup.string().required(),
            answer: yup.string().required(),
            question_priority: yup
                .number()
                .max(question ? total : total + 1)
                .required()
                .positive()
                .integer(),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            category: { id: undefined, name: "" },
            question: "",
            answer: "",
            question_priority: total,
        },
        resolver: yupResolver(schema),
        mode: "onBlur",
    });
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (question != null) {
            setValue("question", question.question);
            setValue("answer", question.answer);
            setValue(
                "category",
                categories.find((category) => category.id === question.category_id)
            );
            setValue("question_priority", question.question_priority);
        } else {
            reset();
            setValue("question_priority", question ? total : total + 1);
        }
    }, [question, open, categories, reset, setValue, total]);

    const onCancel = () => {
        reset();
        handleClose();
    };

    const onSubmit = (data) => {
        if (question) {
            handleEdit({
                id: question.id,
                category_id: data.category.id,
                question: data.question,
                answer: data.answer,
                question_priority: data.question_priority,
            }).then(() => {
                onCancel();
            });
        } else {
            handleCreate({ ...data, category: currentCategory }).then(() => {
                onCancel();
            });
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>{question ? "Edit" : "Create"} FAQ</DialogTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                id="category"
                                label="Category"
                                variant="outlined"
                                value={currentCategory?.name}
                                disabled
                                fullWidth
                                InputProps={{
                                    type: "search",
                                    endAdornment: (
                                        <React.Fragment>
                                            {categoriesLoading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("question")}
                                id="question"
                                label="Question"
                                variant="outlined"
                                fullWidth
                                error={!!errors?.question}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("answer")}
                                id="answer"
                                label="Answer"
                                variant="outlined"
                                fullWidth
                                multiline={true}
                                error={!!errors?.answer}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                {...register("question_priority")}
                                id="priority"
                                label="Priority"
                                variant="outlined"
                                fullWidth
                                multiline={false}
                                type="number"
                                error={!!errors?.question_priority}
                            />
                            <FormHelperText>
                                Lower number means higher position on the list, min value is 1, max
                                priority in this category is {question ? total : total + 1}
                            </FormHelperText>
                            <FormHelperText error={!!errors?.question_priority}>
                                {errors?.question_priority?.message}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCancel}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}>
                        {question ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
