import moment from "moment-timezone";
import React, { Fragment } from "react";

export const MONTH_FORMAT = "YYYY-MM";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const TIME_FORMAT = "HH:mm";

export function chunkDates(batchSize, from, to) {
    const chunks = [];

    let currentDay = moment(from);
    while (currentDay.isSameOrBefore(to)) {
        const timesheetDay = moment(currentDay);
        const timesheetDayTo = moment((currentDay = currentDay.add(batchSize - 1, "day")));

        chunks.push({
            from: timesheetDay,
            to: timesheetDayTo.isSameOrBefore(to) ? timesheetDayTo : to,
        });

        currentDay = currentDay.add(1, "day");
    }

    return chunks;
}

export function formatDate(moment) {
    if (!moment) {
        return moment;
    }

    return moment.format(DATE_FORMAT);
}

export function formatDateTime(moment) {
    if (!moment) {
        return moment;
    }

    return moment.format(DATE_TIME_FORMAT);
}

export function formatTimeSpent(minutes) {
    if (!minutes) {
        return "";
    }

    let output = "";
    if (minutes >= 60) {
        output = Math.floor(minutes / 60.0) + "h ";
    }

    const leftoverMinutes = minutes % 60;
    if (leftoverMinutes) {
        return (output + Math.ceil(leftoverMinutes) + "m").trim();
    }

    return output.trim();
}

export function formatTimeSpentInHours(minutes) {
    if (!minutes) {
        return "";
    }

    let output = "";
    if (minutes >= 60) {
        output = Math.floor(minutes / 60.0) + "h ";
    }

    return output.trim();
}

export function formatTime(minutes) {
    if (minutes === null || minutes === undefined) {
        return "";
    }

    let output = "0";
    if (minutes >= 60) {
        output = Math.floor(minutes / 60.0);
    }

    const leftoverMinutes = minutes % 60;
    if (leftoverMinutes) {
        return (output + ":" + Math.ceil(leftoverMinutes).toString(10).padStart(2, "0")).trim();
    }

    return (output + ":00").trim();
}

export function formatMinutesToIso(minutes) {
    const time = moment().set({
        hour: Math.floor(minutes / 60),
        minute: Math.floor(minutes % 60),
        second: 0,
        millisecond: 0,
    });

    return time.format(TIME_FORMAT);
}

export function getMinutes(date) {
    if (date && typeof date === "string") {
        const localDate = moment(moment.utc(date)).local();
        const time = localDate.format("HH:mm");
        const minutes = getMinutesAfterMidnight(time);
        return minutes;
    } else {
        return date;
    }
}

export function getMinutesAfterMidnight(timeString) {
    const [hours, minutes] = timeString.split(":");
    const hoursInMinutes = parseInt(hours) * 60;
    const minutesAsNumber = parseInt(minutes);
    const totalMinutes = hoursInMinutes + minutesAsNumber;

    return totalMinutes;
}

export function displayHours(data) {
    return data
        ? Object.values(data).map((day, i) => {
              return (
                  <Fragment key={i}>
                      {day.map((date) => {
                          const start = moment(moment.utc(date.start))
                              .local()
                              .format("dddd, MMMM Do YYYY, HH:mm:ss");
                          const end = moment(moment.utc(date.end))
                              .local()
                              .format("dddd, MMMM Do YYYY, HH:mm:ss");
                          return (
                              <li key={date.start}>
                                  <pre>
                                      <b>From </b> {start} <br></br> <b>to </b>
                                      {end}
                                  </pre>
                              </li>
                          );
                      })}
                  </Fragment>
              );
          })
        : null;
}

export function displayDays(data) {
    return data
        ? Object.values(data).map((day) => {
              return (
                  <li key={day}>
                      <pre>
                          <b>{day} </b>
                      </pre>
                  </li>
              );
          })
        : null;
}
