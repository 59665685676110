import React from 'react';
import {getProjects} from "../../../api/api";
import AutoSuggest from "./AutoSuggest";
import {ACCESS_LEVEL} from "./ProjectAccessLevelAutoSuggest";

class ProjectAutoSuggest extends React.Component {
    static defaultProps = {
        onDataFetch: (q) =>
            getProjects(q, ACCESS_LEVEL.USER, null, 0, 32, null, null, true)
                .then(value => value.content)
    }

    render() {
        return (
            <AutoSuggest
                label="Project"
                fetchOptions={(q) => this.props.onDataFetch(q)}
                renderOption={(project) => ({
                    label: `${project.Key} - ${project.Name}`,
                    value: project
                })}
                optionIdentifier={(project) => project.Id}
                debounceTimeout={300}
                {...this.props}
            />
        );
    }
}

export default ProjectAutoSuggest;
