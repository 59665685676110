import React from "react";
import { Card } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProfileImage from "../../userSettings/ProfileImage";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    textAlign: {
        fontWeight: 600,
        wordBreak: "break-word",
    },
    cardTitle: {
        paddingTop: 20,
        paddingLeft: 20,
        wordWrap: "break-word",
    },
    paddingRight: {
        paddingRight: 20,
    },
    paddingContainer: {
        padding: 20,
    },
    paddingItem: {
        paddingBottom: 10,
    },
    textRight: {
        [theme.breakpoints.up("sm")]: {
            textAlign: "right",
        },
    },
}));

export default function UserInfo(props) {
    const user = props.user;
    const classes = useStyles();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
        <Card variant="outlined">
            <Typography variant="h5" className={classes.cardTitle}>
                Personal information
            </Typography>
            <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid container item xs={12} md={4}>
                    <Grid item sm={12} xs={12}>
                        <ProfileImage />
                    </Grid>
                </Grid>
                <Grid container item sm={12} md={6} className={classes.paddingContainer}>
                    <Grid container item className={classes.paddingItem}>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            sm={4}
                            className={isSmallScreen ? "" : classes.textRight}>
                            <Typography className={classes.paddingRight}>Full Name:</Typography>
                        </Grid>
                        <Grid item md={8} xs={12} sm={8}>
                            {user && (
                                <Typography className={classes.textAlign} title={user.FullName}>
                                    {user.FullName}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item className={classes.paddingItem}>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            sm={4}
                            className={isSmallScreen ? "" : classes.textRight}>
                            <Typography className={classes.paddingRight}>Email:</Typography>
                        </Grid>
                        <Grid item md={8} xs={12} sm={8}>
                            {user && (
                                <Typography className={classes.textAlign} title={user.EmailAddress}>
                                    {user.EmailAddress}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item className={classes.paddingItem}>
                        <Grid
                            item
                            md={4}
                            xs={12}
                            sm={4}
                            className={isSmallScreen ? "" : classes.textRight}>
                            <Typography className={classes.paddingRight}>Contract:</Typography>
                        </Grid>
                        <Grid item md={8} xs={12} sm={8}>
                            {user && (
                                <Typography className={classes.textAlign} title={user.Contract}>
                                    {user.Contract ? user.Contract : "-"}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
