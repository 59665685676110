import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogContentText } from "@material-ui/core";
import Changelog from "./Changelog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { Fragment, useState, useEffect } from "react";

export default function ChangelogDialog() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const version = localStorage.getItem("appVersion");

        const currentVersion = process.env.REACT_APP_VERSION;
        if (version !== currentVersion) {
            setOpen(true);
            localStorage.setItem("appVersion", currentVersion);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <Changelog />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
