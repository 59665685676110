import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import MenuButton from "../menuItem/MenuButton";
import MenuLink from "../menuItem/MenuLink";
import LoggedAs from "containers/components/misc/LoggedAs";
import { STATE } from "../autoSuggest/UserStateAutoSuggest";
import Version from "containers/components/misc/Version";
import { assertUserPermissions } from "utils/routePermissions";
import ThemeSwitchButton from "../themeSwitchButton/ThemeSwitchButton";
//
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
//
import HomeIcon from "@material-ui/icons/Home";
import TableChartIcon from "@material-ui/icons/TableChart";
import LockIcon from "@material-ui/icons/Lock";
import NotificationsPausedIcon from "@material-ui/icons/NotificationsPaused";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TTMSLogo from "../../components/logo/TTMSLogo";
import RoomIcon from "@material-ui/icons/Room";
import LiveHelp from "@material-ui/icons/LiveHelp";
import Badge from "@material-ui/core/Badge";
import SettingsIcon from "@material-ui/icons/Settings";
import EventIcon from "@material-ui/icons/Event";

import { withStyles } from "@material-ui/core/styles";
import { NoticeContext } from "App";
import ChangelogDialog from "../changelog/ChangelogDialog";
import { NewReleasesOutlined } from "@material-ui/icons";

const drawerWidth = "240px";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 8,
        top: 8,
        padding: "0 4px",
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    buttonBorder: {
        border: "1px solid",
        borderColor: theme.palette.text.secondary,
        borderRadius: 12,
        padding: theme.spacing(1),
        width: 40,
        height: 40,
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
            boxShadow: "4px 0px 6px rgba(0, 0, 0, 0.16)",
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    drawerToggleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: theme.spacing(2),

        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    versionItem: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        padding: "4px",
    },
}));

function SideMenu(props) {
    const { user, mobileOpen } = props;
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    const onLogout = props.onLogoutHandler;
    const onLogin = props.onLoginHandler;
    const handleDrawerToggle = props.handleDrawerToggle;
    const { overtimes, accessRequests } = useContext(NoticeContext);
    const [openDialog, setOpenDialog] = useState(false);

    const gotoSettings = () => {
        handleDrawerToggle();
        history.push("/settings");
    };

    const goToChangelog = () => {
        handleDrawerToggle();
        history.push("/changelog");
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    return (
        <nav className={classes.drawer}>
            <Drawer
                variant={isSmUp ? "permanent" : "temporary"}
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true,
                }}>
                <Box className={classes.drawerToggleContainer}>
                    <IconButton onClick={handleDrawerToggle}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>

                <Divider />

                <div className="brand-container">
                    <TTMSLogo className="brand-logo" />
                    <Typography component={isSmUp ? "h1" : "span"} className="brand-title">
                        TimeSheets
                    </Typography>
                </div>

                <Divider />

                <List
                    id="menu"
                    component="menu"
                    className="main-menu"
                    aria-labelledby="nested-list-subheader">
                    <ListItem>
                        <Grid container spacing={1}>
                            <Grid item>
                                <ThemeSwitchButton />
                            </Grid>
                            {props.user && (
                                <Grid item>
                                    <Tooltip title="User settings">
                                        <IconButton
                                            onClick={gotoSettings}
                                            color="primary"
                                            className={classes.buttonBorder}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            {props.user && (
                                <Grid item>
                                    <Tooltip title="Release notes">
                                        <IconButton
                                            onClick={goToChangelog}
                                            color="primary"
                                            className={classes.buttonBorder}>
                                            <NewReleasesOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            {props.user && (
                                <Grid item>
                                    <ChangelogDialog open={openDialog} />
                                </Grid>
                            )}
                        </Grid>
                    </ListItem>

                    <LoggedAs user={user} />

                    {user && user.State === STATE.ACTIVE && (
                        <MenuLink
                            to="/"
                            name="Home"
                            handler={handleDrawerToggle}
                            icon={<HomeIcon />}
                        />
                    )}
                    {assertUserPermissions("/users", user?.Permissions) && (
                        <MenuLink
                            to="/users"
                            name="Users"
                            handler={handleDrawerToggle}
                            icon={<PersonAddIcon />}
                        />
                    )}
                    {assertUserPermissions("/groups", user?.Permissions) && (
                        <MenuLink
                            to="/groups"
                            name="Groups"
                            handler={handleDrawerToggle}
                            icon={<PersonAddIcon />}
                        />
                    )}
                    {assertUserPermissions("/access-requests", user?.Permissions) && (
                        <MenuLink
                            to="/access-requests"
                            name="Access requests"
                            handler={handleDrawerToggle}
                            icon={
                                <StyledBadge
                                    badgeContent={accessRequests}
                                    max={9}
                                    color="error"
                                    overlap="rectangular">
                                    <AssignmentIndIcon />
                                </StyledBadge>
                            }
                        />
                    )}
                    {assertUserPermissions("/overtime", user?.Permissions) && (
                        <MenuLink
                            disabled
                            to="/overtime"
                            name="Overtime requests"
                            handler={handleDrawerToggle}
                            icon={
                                <StyledBadge
                                    invisible
                                    badgeContent={overtimes}
                                    max={9}
                                    color="error"
                                    overlap="rectangular">
                                    <AssignmentIndIcon />
                                </StyledBadge>
                            }
                        />
                    )}
                    {assertUserPermissions("/projects", user?.Permissions) && (
                        <MenuLink
                            to="/projects"
                            name="Projects"
                            handler={handleDrawerToggle}
                            icon={<AssignmentIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/advanced", user?.Permissions) && (
                        <MenuLink
                            to="/reports/advanced"
                            name="Advanced report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/presence", user?.Permissions) && (
                        <MenuLink
                            to="/reports/presence"
                            name="Presence report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/projects", user?.Permissions) && (
                        <MenuLink
                            to="/reports/projects"
                            name="Project report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/project-plus", user?.Permissions) && (
                        <MenuLink
                            to="/reports/project-plus"
                            name="Project report plus"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/users", user?.Permissions) && (
                        <MenuLink
                            to="/reports/users"
                            name="Users report"
                            handler={handleDrawerToggle}
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/reports/customer", user?.Permissions) && (
                        <MenuLink
                            to="/reports/customer"
                            name="Customer report"
                            icon={<TableChartIcon />}
                        />
                    )}
                    {assertUserPermissions("/lock", user?.Permissions) && (
                        <MenuLink
                            to="/lock"
                            name="Lock worklogs"
                            handler={handleDrawerToggle}
                            icon={<LockIcon />}
                        />
                    )}
                    {assertUserPermissions("MailModule", user?.Permissions) && (
                        <MenuLink
                            to="/mail"
                            name="Notification manager"
                            handler={handleDrawerToggle}
                            icon={<NotificationsPausedIcon />}
                        />
                    )}
                    {assertUserPermissions("/locations", user?.Permissions) && (
                        <MenuLink
                            to="/locations"
                            name="Locations"
                            handler={handleDrawerToggle}
                            icon={<RoomIcon />}
                        />
                    )}
                    {assertUserPermissions("/templatesManager", user?.Permissions) && (
                        <MenuLink
                            to="/templatesManager"
                            name="Templates Manager"
                            handler={handleDrawerToggle}
                            icon={<EventIcon />}
                        />
                    )}
                    {user && (
                        <MenuLink
                            to="/help"
                            name="FAQ"
                            handler={handleDrawerToggle}
                            icon={<LiveHelp />}
                        />
                    )}

                    {user ? (
                        <MenuButton
                            name="Logout"
                            icon={<PowerSettingsNewIcon />}
                            handler={onLogout}
                        />
                    ) : (
                        <MenuButton name="Login" icon={<ExitToAppIcon />} handler={onLogin} />
                    )}

                    <ListItem className={classes.versionItem}>
                        <Version />
                    </ListItem>
                </List>
            </Drawer>
        </nav>
    );
}

export default SideMenu;
