import React from "react";
//
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//
import { registerLoadListener, unregisterLoadListener } from "../../api/api";

const styles = (theme) => ({
    paper: {
        width: "100%",
        height: "100%",
        outline: "none",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    wrapper: {
        position: "relative",
        width: "128px",
        height: "128px",
        "border-radius": "128px",
        "background-color": "rgba(0, 0, 0, 0.4)",
    },
    progress: { boxSizing: "border-box", padding: "4px" },
    progressSmallerWrapper: {
        position: "absolute",
    },
    progressSmaller: {
        padding: "8px",
    },
    progressText: {
        position: "absolute",
        top: "48px",
        width: "128px",
        "text-align": "center",
        color: "#bbb",
        "font-weight": "bold",
        "font-size": "28px",
    },
});

class Loading extends React.Component {
    constructor(props) {
        super(props);

        this.onLoad = this.onLoad.bind(this);

        this.state = {
            loadingProgress: null,
        };
    }

    componentDidMount() {
        registerLoadListener(this.onLoad);
    }

    componentWillUnmount() {
        unregisterLoadListener(this.onLoad);
    }

    onLoad(isLoading, loadingProgress) {
        if (loadingProgress !== this.state.loadingProgress) {
            this.setState({ loadingProgress });
        }
    }

    render() {
        const { classes } = this.props;
        const { loadingProgress } = this.state;
        const hasProgress = loadingProgress != null;

        return (
            <div className={classes.paper} tabIndex={-1}>
                <div className={classes.wrapper}>
                    <CircularProgress
                        size={128}
                        variant="indeterminate"
                        className={classes.progress}
                        disableShrink
                    />
                    {hasProgress && <div className={classes.progressText}>{loadingProgress}%</div>}
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Loading);
