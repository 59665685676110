import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    categoryPanel: {
        margin: "1rem",
        width: "450px",
        height: "175px",
    },
    positionCategoryPanel: {
        margin: "1rem",
        height: "fit-content",
        width: "500px",
        cursor: "grab",
        fontSize: "1rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
        color: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
    },
    positionCategoryPanelLabel: {
        color: theme.palette.background.paper,
        height: "100%",
        alignItems: "normal",
        textAlign: "center",
        padding: "1rem",
    },
    categoryPanelLabel: {
        color: theme.palette.background.paper,
        height: "100%",
        alignItems: "normal",
    },
    categoryTitleDivider: {
        backgroundColor: theme.palette.background.paper,
    },
    categoryTitleContainer: {
        height: "50px",
    },
    categoryTitle: {
        fontSize: "1rem",
    },
    categorySubtitle: {
        height: "60%",
        textTransform: "none",
        fontSize: "0.8rem",
    },
}));

function FAQCategoryPanel({ category, positioningMode }) {
    const classes = useStyles();

    return (
        <>
            {positioningMode ? (
                <Box className={classes.positionCategoryPanel}>
                    {/* <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignContent="center"
                        className={classes.categoryTitleContainer}>
                        <Grid item> */}
                    <Typography className={classes.positionCategoryPanelLabel}>
                        {category.name}
                    </Typography>
                    {/* </Grid>
                    </Grid> */}
                </Box>
            ) : (
                <Button
                    component={RouterLink}
                    to={`/help/category/${category.id}`}
                    variant="contained"
                    color="primary"
                    classes={{
                        root: classes.categoryPanel,
                        label: classes.categoryPanelLabel,
                    }}>
                    <Grid container direction="column" spacing={1}>
                        <Grid
                            item
                            container
                            direction="column"
                            justifyContent="center"
                            alignContent="center"
                            className={classes.categoryTitleContainer}>
                            <Grid item>
                                <Typography align="center" className={classes.categoryTitle}>
                                    {category.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider className={classes.categoryTitleDivider} />
                        </Grid>
                        <Grid item>
                            <Typography align="center" className={classes.categorySubtitle}>
                                {category.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Button>
            )}
        </>
    );
}

export default FAQCategoryPanel;
