import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function CenteredView({ children }) {
    return (
        <Grid container justifyContent="space-around">
            <Grid item>
                <Typography variant="h6" className="content-login">
                    {children}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default CenteredView;
