import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

function FAQSearch() {
    const queryParams = useQuery();
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setSearchQuery(queryParams.get("query") || "");
    }, [queryParams]);

    const handleSearch = () => {
        history.push(`/help/search?query=${searchQuery}&page=1`);
    };

    return (
        <TextField
            label="Search FAQ"
            fullWidth
            onChange={(e) => {
                setSearchQuery(e.target.value);
            }}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    handleSearch();
                }
            }}
            value={searchQuery}
            placeholder="Search..."
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="Search FAQ" onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default FAQSearch;
