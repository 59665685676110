import React from 'react';
import {getGroupsPage} from "../../../api/api";
import AutoSuggest from "./AutoSuggest";

class GroupAutoSuggest extends React.Component {
    static defaultProps = {
        onDataFetch: (q) =>
            getGroupsPage(q, 0, 4,null, null, true)
                .then(value => value.content)
    }

    render() {
        return (
            <AutoSuggest
                label="Group"
                fetchOptions={(q) => this.props.onDataFetch(q)}
                renderOption={(group) => ({
                    label: `${group.name}`,
                    value: group
                })}
                optionIdentifier={(group) => group.id}
                debounceTimeout={300}
                {...this.props}
            />
        );
    }
}

export default GroupAutoSuggest;
