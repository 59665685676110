import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "./CustomTable.scss";

const useStyles = makeStyles((theme) => ({
    tableHeadCell: {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#363636",
    },
}));

function ResultTable(props) {
    const { headers, rows, rowKeyName } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const rowIds = headers.map((header) => header.key);

    return (
        <Paper className="result-table-wrapper">
            <TableContainer className="table-container">
                <Table className="table" size={isMdUp ? "medium" : "small"} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => (
                                <TableCell
                                    key={i}
                                    align={header.align || "right"}
                                    className={classes.tableHeadCell}>
                                    {header.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows !== null &&
                            rows.map((row, i) => (
                                <TableRow key={rowKeyName ? row[rowKeyName] : i} hover={true}>
                                    {rowIds.map((rowKey, i) =>
                                        getCellValue(rowKey, row[rowKey], headers[i])
                                    )}
                                </TableRow>
                            ))}
                        {(!rows || rows.length === 0) && (
                            <TableRow>
                                <TableCell align="center" colSpan={headers.length}>
                                    {rows === null && <b />}
                                    {rows && rows.length === 0 && <b>No entries</b>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

    function getCellValue(key, value, header) {
        const mapper = header && header.mapper;

        if (!mapper) {
            return (
                <TableCell key={key} align={header.align || "right"}>
                    {value}
                </TableCell>
            );
        }

        return (
            <TableCell key={key} align={header.align || "right"}>
                {mapper(value)}
            </TableCell>
        );
    }
}

export default ResultTable;
