import React, { useEffect, useState, useMemo, useCallback } from "react";

import { SelectedThemeContext } from "theme/themeContext";
import { ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { lightTheme } from "theme/lightTheme";
import { darkTheme } from "theme/darkTheme";

const acceptedThemes = ["light", "dark"];

function ThemeWrapper({ children }) {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", { noSsr: true });
    const userSavedTheme = localStorage.getItem("theme");
    const getThemePreference = useCallback(() => {
        if (userSavedTheme && acceptedThemes.includes(userSavedTheme)) {
            return userSavedTheme;
        } else {
            return prefersDarkMode ? "dark" : "light";
        }
    }, [userSavedTheme, prefersDarkMode]);
    const [selectedTheme, setSelectedTheme] = useState(getThemePreference(prefersDarkMode));
    const theme = selectedTheme === "light" ? lightTheme : darkTheme;

    const colorMode = useMemo(
        () => ({
            toggleTheme: () => {
                setSelectedTheme((prevMode) => {
                    const nextMode = prevMode === "light" ? "dark" : "light";

                    localStorage.setItem("theme", nextMode);
                    return nextMode;
                });
            },
        }),
        []
    );

    useEffect(() => {
        const themePreference = getThemePreference(prefersDarkMode);
        const htmlTag = document.documentElement;

        setSelectedTheme(themePreference);
        htmlTag.className = `theme-${themePreference}`;
    }, [prefersDarkMode, userSavedTheme, getThemePreference]);

    return (
        <SelectedThemeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </SelectedThemeContext.Provider>
    );
}

export default ThemeWrapper;
