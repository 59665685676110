import React from "react";
import AutoSuggest from "./AutoSuggest";

export const ACCESS_LEVEL = {
    USER: "USER",
    REPORTS: "REPORTS",
    ADMINISTRATION: "ADMINISTRATION"
};

export const ACCESS_LEVEL_TO_LABEL = {
    [ACCESS_LEVEL.USER]: "User",
    [ACCESS_LEVEL.REPORTS]: "Reports",
    [ACCESS_LEVEL.ADMINISTRATION]: "Administration"
};

class ProjectAccessLevelAutoSuggest extends React.Component {
    render() {
        return (
            <AutoSuggest
                label="Access level"
                fetchOptions={this.suggestRole.bind(this)}
                renderOption={(accessLevel) => ({
                    value: accessLevel,
                    label: ACCESS_LEVEL_TO_LABEL[accessLevel]
                })}
                optionIdentifier={(value) => value}
                {...this.props}
            />
        );
    }

    suggestRole(q) {
        if (q) {
            q = q.toLocaleLowerCase();
        } else {
            q = "";
        }

        return Promise.resolve(
            Object.keys(ACCESS_LEVEL_TO_LABEL)
                .filter(accessKey => (ACCESS_LEVEL_TO_LABEL[accessKey] || "").toLocaleLowerCase().indexOf(q) >= 0)
        );
    }
}

export default ProjectAccessLevelAutoSuggest;
