import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

export default function ControlledAutocomplete({
    name,
    label,
    options,
    multiple = false,
    getOptionLabel,
    renderOption,
    handleChange,
    control,
    error = false,
    type = "text",
    ...props
}) {
    const sanitizeValue = (value) => {
        if (value != null) {
            return value;
        }

        // convert null/undefined to valid value depending on 'multiple' setting
        return multiple ? [] : "";
    };

    return (
        <Controller
            render={({ field: { onChange, value } }) => {
                const sanitizedValue = sanitizeValue(value);

                return (
                    <Autocomplete
                        multiple={multiple}
                        id={name}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        onChange={async (event, item) => onChange(await handleChange(item))}
                        value={sanitizedValue}
                        {...props}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                error={!!error}
                                InputProps={{
                                    ...params.InputProps,
                                    type: type,
                                    endAdornment: (
                                        <React.Fragment>
                                            {!!props.loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                );
            }}
            onChange={([, data]) => data}
            name={name}
            control={control}
        />
    );
}
