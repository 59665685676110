import React, {Component, Fragment} from "react";
import {markLoaded, markLoading} from "../../api/api";

class ViewPlaceholder extends Component {
    render() {
        return <Fragment/>
    }

    componentDidMount() {
        markLoading();
    }

    componentWillUnmount() {
        markLoaded();
    }
}

export default ViewPlaceholder;
