import React from "react";
import { useLocation, Redirect } from "react-router-dom";

import CenteredView from "../centeredView/CenteredView";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function Unauthorized() {
    const location = useLocation();
    const redirectBackElement = location.state?.redirectBackElement;

    if (!location.state?.unauthorized) {
        return <Redirect to="/" />;
    }

    return (
        <CenteredView>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Typography variant="h6" component="span">
                        {"Insufficient permissions to access this page."}
                    </Typography>
                </Grid>
                {redirectBackElement && <Grid item>{location.state.redirectBackElement}</Grid>}
            </Grid>
        </CenteredView>
    );
}

export default Unauthorized;
