import React, { useContext, useState, useEffect } from "react";
import moment from "moment-timezone";
//
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
//
import { ACCESS_LEVEL } from "containers/components/autoSuggest/ProjectAccessLevelAutoSuggest";
import { PRESENCE_TO_LABEL } from "../../components/autoSuggest/PresenceAutoSuggest";
import Info from "../../info/Info";
import Confirmation from "containers/components/confirmation/Confirmation";
import DatePicker from "../../components/datePicker/DatePicker";
import GroupAutoSuggest from "containers/components/autoSuggest/GroupAutoSuggest";
import ResultTableVirtualized from "containers/components/tables/ResultTableVirtualized";

import UserAutoSuggest from "../../components/autoSuggest/UserAutoSuggest";
//
import {
    getProjectReportPlusPreview,
    getProjectReportPlusXlsxExportUrl,
    getProjectsWithTasks,
    saveProjectReportPlusToSql,
    getProjectsReportPlusPdfUrl,
} from "../../../api/api";
import { formatDate, formatTimeSpent } from "../../../api/date";
import { UserContext } from "../../../App";
import TreePicker from "../../components/treePicker/TreePicker";
import ResultTable from "containers/components/tables/ResultTable";
//

const MAX_PAGE_SIZE = 2147483647;

export const GROUP_BY_ENUM = {
    DAY: "DAY",
    MONTH: "MONTH",
    NONE: "NONE",
};

const GROUP_BY_TO_HEADERS = {
    [GROUP_BY_ENUM.MONTH]: [
        { key: "Date", name: "Started" },
        { key: "TimeSpent", name: "TimeSpent (h)", mapper: formatTimeSpent },
        { key: "UserFullName", name: "User Full Name" },
        { key: "UserEmail", name: "User" },
        { key: "ProjectTitle", name: "Project" },
    ],
    [GROUP_BY_ENUM.DAY]: [
        { key: "Date", name: "Started" },
        { key: "TimeSpent", name: "TimeSpent (h)", mapper: formatTimeSpent },
        { key: "UserFullName", name: "User Full Name" },
        { key: "UserEmail", name: "User" },
        { key: "ProjectTitle", name: "Project" },
        { key: "Presence", name: "Presence", mapper: (presence) => PRESENCE_TO_LABEL[presence] },
    ],
    [GROUP_BY_ENUM.NONE]: [
        { key: "Date", name: "Started" },
        { key: "TimeSpent", name: "TimeSpent (h)", mapper: formatTimeSpent },
        { key: "UserFullName", name: "User Full Name" },
        { key: "UserEmail", name: "User" },
        { key: "TaskKey", name: "Task Name" },
        { key: "TaskTitle", name: "Task Summary" },
        { key: "ProjectTitle", name: "Project" },
        { key: "Comment", name: "Comment" },
        { key: "Presence", name: "Presence", mapper: (presence) => PRESENCE_TO_LABEL[presence] },
    ],
};

const headers = [
    {
        key: "userEmail",
        name: "Email",
        align: "left",
    },
    {
        key: "timeSpent",
        name: "Grand total",
        align: "left",
    },
];

function mapProjectToTreeItem(project, children) {
    return {
        id: `P_${project.id}`,
        label: `${project.key} - ${project.title}`,
        parentId: null,
        children: children.map((task) => mapTaskToTreeItem(task, `P_${project.id}`)),
    };
}

function mapTaskToTreeItem(task, projectId) {
    return {
        id: `T_${task.taskId}`,
        label: `${task.key} - ${task.title}`,
        parentId: projectId,
        children: [],
    };
}

const useStyles = makeStyles((theme) => ({
    button: {
        height: "100%",
    },
    neutralContainedButton: {
        height: "100%",

        ...(theme.palette.type === "dark"
            ? {
                  background: theme.palette.background.paper,
                  color: theme.palette.common.white,

                  "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
              }
            : {}),
    },
}));

export default function ProjectReportPlus() {
    const classes = useStyles();
    const contextUser = useContext(UserContext);
    const [projectItems, setProjectItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [user, setUser] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupBy, setGroupBy] = useState(GROUP_BY_ENUM.NONE);
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, "month").startOf("month"));
    const [dateTo, setDateTo] = useState(moment().subtract(1, "month").endOf("month"));
    const [results, setResults] = useState(null);
    const [grandTotal, setGrandTotal] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [reportName, setReportName] = useState("Project Report");
    const dateFromError = !dateFrom || !dateFrom.isValid() || (dateTo && dateFrom.isAfter(dateTo));
    const dateToError = !dateTo || !dateTo.isValid() || (dateFrom && dateFrom.isAfter(dateTo));
    const proceedDisabled = dateFromError || dateToError || !selectedItems.length;

    useEffect(() => {
        function fetchTasksWithProjects() {
            getProjectsWithTasks(
                {
                    email: contextUser.EmailAddress,
                    accessLevel: contextUser?.Permissions?.GlobalAdministration
                        ? ""
                        : ACCESS_LEVEL.REPORTS,
                    page: 0,
                    size: MAX_PAGE_SIZE,
                },
                false
            ).then((value) => {
                const items = value.map((projectWithTasks) => {
                    const tasks = Array.isArray(projectWithTasks.tasks)
                        ? projectWithTasks.tasks
                        : [];

                    return mapProjectToTreeItem(projectWithTasks.project, tasks);
                });

                setProjectItems(items);
            });
        }

        fetchTasksWithProjects();
    }, [contextUser]);

    const onReportXlsxExport = () => {
        getProjectReportPlusXlsxExportUrl(getReportParameters());
    };

    const onReportPdfExport = () => {
        const params = {
            dateFrom: formatDate(dateFrom),
            dateTo: formatDate(dateTo),
            groupBy: groupBy,
            taskIds: getTaskIdsFromItems(selectedItems),
            userId: user ? user.Id : null,
            groupId: group ? group.id : null,
        };

        getProjectsReportPlusPdfUrl(params);
    };

    const onReportSaveToSql = () => {
        if (proceedDisabled) {
            return;
        }

        return saveProjectReportPlusToSql(reportName, getReportParameters(), false).then(
            (response) => {
                setShowSaveConfirmation(false);
                setResults(response.results || []);
                setReportName("Project Report");
                setInfoMessage(`Saved report to SQL with ID #${response.reportId}`);
            }
        );
    };

    const onReportPreview = () => {
        if (proceedDisabled) {
            return;
        }

        getProjectReportPlusPreview(getReportParameters(), false).then((results) => {
            const grandTotal = [];
            for (const [key, value] of Object.entries(results.sumUserGrantTotal)) {
                grandTotal.push({
                    userEmail: key,
                    timeSpent: formatTimeSpent(value),
                });
            }
            setResults(results.reportsRows);
            setGrandTotal(grandTotal);
        });
    };

    const getIdsFromItems = (items, prefix) => {
        return items.filter((item) => item[0] === prefix).map((item) => item.slice(2));
    };

    const getTaskIdsFromItems = (items) => {
        return getIdsFromItems(items, "T");
    };

    const getReportParameters = () => {
        return {
            dateFrom: formatDate(dateFrom),
            dateTo: formatDate(dateTo),
            groupBy: groupBy,
            taskIds: getTaskIdsFromItems(selectedItems),
            userId: user ? user.Id : null,
            groupId: group ? group.id : null,
        };
    };

    return (
        <>
            <Info
                open={!!infoMessage}
                type="Information"
                message={infoMessage}
                onClose={() => setInfoMessage(null)}
            />
            <Confirmation
                open={showSaveConfirmation}
                confirmLabel="Save"
                onConfirm={onReportSaveToSql}
                onClose={() => setShowSaveConfirmation(false)}>
                <TextField
                    label="Report Name"
                    variant="filled"
                    margin="normal"
                    fullWidth
                    value={reportName || ""}
                    onChange={(e) => setReportName(e.target.value)}
                />
            </Confirmation>

            <h2 className="custom-title">Project report plus</h2>
            <Grid container spacing={3} className="project-report">
                <Grid item xs={12} md={6}>
                    <TreePicker
                        items={projectItems}
                        selectedItems={selectedItems}
                        setSelectedItems={(newItems) => {
                            setSelectedItems(newItems);
                            setResults(null);
                            setGrandTotal(null);
                        }}
                        accessLevel={
                            contextUser?.Permissions?.GlobalAdministration
                                ? ""
                                : ACCESS_LEVEL.REPORTS
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <UserAutoSuggest
                                value={user}
                                onChange={(user) => {
                                    setUser(user);
                                    setResults(null);
                                    setGrandTotal(null);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            direction="column"
                            justifyContent="flex-start">
                            <FormControl className="groupBy-container">
                                <FormLabel>
                                    <Typography component="span" variant="subtitle2">
                                        {"Group by:"}
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    name="group"
                                    row
                                    defaultValue={groupBy}
                                    onChange={(e) => {
                                        setGroupBy(e.target.value);
                                        setResults(null);
                                        setGrandTotal(null);
                                    }}>
                                    <FormControlLabel
                                        value={GROUP_BY_ENUM.DAY}
                                        control={<Radio />}
                                        label="Day"
                                    />
                                    <FormControlLabel
                                        value={GROUP_BY_ENUM.MONTH}
                                        control={<Radio />}
                                        label="Month"
                                    />
                                    <FormControlLabel
                                        value={GROUP_BY_ENUM.NONE}
                                        control={<Radio />}
                                        label="None"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Date from:"
                                value={dateFrom}
                                onChange={(dateFrom) => {
                                    setDateFrom(dateFrom);
                                    setResults(null);
                                    setGrandTotal(null);
                                }}
                                error={dateFromError}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label="Date to:"
                                fullWidth
                                value={dateTo}
                                minDate={dateFrom}
                                onChange={(dateTo) => {
                                    setDateTo(dateTo);
                                    setResults(null);
                                    setGrandTotal(null);
                                }}
                                error={dateToError}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <GroupAutoSuggest value={group} onChange={(group) => setGroup(group)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent={"center"} spacing={3}>
                                <Grid item xs={6} xl={3}>
                                    <Button
                                        variant="text"
                                        onClick={onReportPreview}
                                        disabled={proceedDisabled}
                                        fullWidth
                                        className={classes.button}>
                                        View
                                    </Button>
                                </Grid>
                                <Grid item xs={6} xl={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onReportPdfExport}
                                        disabled={proceedDisabled}
                                        fullWidth
                                        className={classes.button}>
                                        Export as PDF
                                    </Button>
                                </Grid>
                                {contextUser.Permissions.GlobalReports && (
                                    <Grid item xs={6} xl={3}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setShowSaveConfirmation(true)}
                                            disabled={proceedDisabled}
                                            fullWidth
                                            className={classes.neutralContainedButton}>
                                            Save to SQL
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={6} xl={3}>
                                    <Button
                                        variant="contained"
                                        onClick={onReportXlsxExport}
                                        disabled={proceedDisabled}
                                        fullWidth
                                        className={classes.neutralContainedButton}>
                                        Export as spreadsheet
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <ResultTableVirtualized headers={GROUP_BY_TO_HEADERS[groupBy]} rows={results} />
                </Grid>
                {results && grandTotal && (
                    <Grid item xs={12}>
                        <ResultTable headers={headers} rows={grandTotal} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
