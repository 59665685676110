import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Flowers from "../../../assets/womens_day/tulips4.png";
import Title from "../../../assets/womens_day/happy.png";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundImage: `url(${Flowers})`,
        backgroundRepeat: "repeat",
        height: "90px",
        backgroundSize: "200px",
        backgroundPosition: "bottom",
        zIndex: 9,
        marginBottom: "5px",
    },
    title: {
        backgroundImage: `url(${Title})`,
        backgroundRepeat: "no-repeat",
        width: "300px",
        height: "100px",
        backgroundSize: "140px",
        backgroundPosition: "top right",
        zIndex: 10,
    },
}));

export default function WomensDay() {
    const classes = useStyles();
    return (
        <Fragment>
            <Grid container spacing={3} className={classes.banner} justifyContent="flex-end">
                <Box className={classes.title}></Box>
            </Grid>
        </Fragment>
    );
}
