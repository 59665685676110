import React, {Fragment} from "react";
import Confetti from "react-confetti";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
    },
}));

export default function Valentines() {
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.container}>
                <Confetti
                    numberOfPieces={30}
                    colors={["#FF0000", "#eb8686", "#f34d82"]}
                    drawShape={(ctx) => {
                        ctx.beginPath();
                        const x = 0;
                        const y = 0;
                        const width = 50; // Adjust size as needed
                        const height = 50; // Adjust size as needed
                        const topCurveHeight = height * 0.3;
                        ctx.moveTo(x, y + topCurveHeight);
                        // top left curve
                        ctx.bezierCurveTo(
                            x,
                            y,
                            x - width / 2,
                            y,
                            x - width / 2,
                            y + topCurveHeight
                        );

                        // bottom left curve
                        ctx.bezierCurveTo(
                            x - width / 2,
                            y + (height + topCurveHeight) / 2,
                            x,
                            y + (height + topCurveHeight) / 2,
                            x,
                            y + height
                        );

                        // bottom right curve
                        ctx.bezierCurveTo(
                            x,
                            y + (height + topCurveHeight) / 2,
                            x + width / 2,
                            y + (height + topCurveHeight) / 2,
                            x + width / 2,
                            y + topCurveHeight
                        );

                        // top right curve
                        ctx.bezierCurveTo(x + width / 2, y, x, y, x, y + topCurveHeight);

                        ctx.closePath();
                        ctx.fill();
                    }}
                />
            </div>
        </Fragment>
    );
}
