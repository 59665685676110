import React from 'react';
import {getTasks} from "../../../api/api";
import AutoSuggest from "./AutoSuggest";
import {STATE} from "./TaskStateAutoSuggest";

class TaskAutoSuggest extends React.Component {
    static defaultProps = {
        taskState: STATE.ACTIVE
    }

    render() {
        return (
            <AutoSuggest
                label="Task"
                disabled={!this.props.project}
                arg={this.props.project && this.props.project.Id}
                fetchOptions={(q, projectId) =>
                    getTasks(projectId, q, this.props.taskState, null, 0, 15, null, null, true)
                        .then(value => value.content)
                }
                renderOption={(task) => ({
                    label: `${task.Name} - ${task.Summary}` + (task.Closed ? ' (Closed)' : ''),
                    value: task
                })}
                optionIdentifier={(task) => task.Id}
                debounceTimeout={300}
                {...this.props}
            />
        );
    }
}

export default TaskAutoSuggest;
