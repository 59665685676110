import React from "react";
import AutoSuggest from "./AutoSuggest";
import { getLocations } from "api/api";

const LOCATIONS = {
    POLAND: "Europe/Warsaw",
};

export async function guessLocation(preferredLocation, user) {
    const locations = await getLocations(false, false);

    if (preferredLocation) {
        const preferredLocations = locations.filter(
            (location) => location.key === preferredLocation
        );

        if (preferredLocations?.length === 1) {
            return preferredLocations[0];
        }
    }

    if (user.location) {
        return user.location;
    }

    return locations.find((location) => location.key === LOCATIONS.POLAND);
}

function LocationAutoSuggest(props) {
    const suggestLocation = (q) => {
        if (q) {
            q = q.toLocaleLowerCase();
        }

        return Promise.resolve(
            getLocations().then((data) => {
                return data.filter(
                    (location) => (location.title || "").toLocaleLowerCase().indexOf(q) >= 0
                );
            })
        );
    };

    return (
        <AutoSuggest
            label="Location"
            fetchOptions={(q) => suggestLocation(q)}
            renderOption={(location) => ({ value: location, label: location.title })}
            optionIdentifier={(location) => {
                return location.title;
            }}
            debounceTimeout={300}
            {...props}
        />
    );
}

export default LocationAutoSuggest;
