import React, { useState, useEffect } from "react";
import { Person } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getUserPhoto } from "../../api/api";

const useStyles = makeStyles((theme) => ({
    personIcon: {
        width: 220,
        height: 220,
        borderRadius: 120,
        color: theme.palette.primary.light,
    },
    avatarContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
}));

export default function ProfileImage() {
    const [imageData, setImageData] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        getUserPhoto()
            .then((url) => {
                setImageData(url);
            });
    }, []);

    return imageData != null ? (
        <div className={classes.avatarContainer}>
            {imageData && (
                <img className={classes.personIcon} src={`data:image/jpeg;base64,${imageData}`} alt="profile image"/>
            )}
        </div>
    ) : (
        <div className={classes.avatarContainer}>
            <Person className={classes.personIcon} />
        </div>
    );
}
