const PERMISSIONS = {
    ADMINISTRATION: "Administration",
    IMPERSONATION: "Impersonation",
    IMPERSONATION_PROJECTS: "ImpersonationProjects",
    GLOBAL_ADMINISTRATION: "GlobalAdministration",
    GLOBAL_REPORTS: "GlobalReports",
    REPORTS: "Reports",
    LOCKING: "Locking",
};

const routePermissions = {
    "/users": [PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/groups": [
        PERMISSIONS.ADMINISTRATION,
        PERMISSIONS.GLOBAL_ADMINISTRATION,
        PERMISSIONS.GLOBAL_REPORTS,
        PERMISSIONS.REPORTS,
    ],
    "/access-requests": [PERMISSIONS.ADMINISTRATION, PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/overtime": [PERMISSIONS.ADMINISTRATION, PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/projects": [PERMISSIONS.ADMINISTRATION, PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/reports/advanced": [PERMISSIONS.GLOBAL_ADMINISTRATION, PERMISSIONS.GLOBAL_REPORTS],
    "/reports/presence": [PERMISSIONS.GLOBAL_ADMINISTRATION, PERMISSIONS.GLOBAL_REPORTS],
    "/reports/projects": [
        PERMISSIONS.ADMINISTRATION,
        PERMISSIONS.GLOBAL_ADMINISTRATION,
        PERMISSIONS.GLOBAL_REPORTS,
        PERMISSIONS.REPORTS,
    ],
    "/reports/project-plus": [
        PERMISSIONS.ADMINISTRATION,
        PERMISSIONS.GLOBAL_ADMINISTRATION,
        PERMISSIONS.GLOBAL_REPORTS,
        PERMISSIONS.REPORTS,
    ],
    "/reports/users": [PERMISSIONS.GLOBAL_ADMINISTRATION, PERMISSIONS.GLOBAL_REPORTS],
    "/reports/customer": [
        PERMISSIONS.ADMINISTRATION,
        PERMISSIONS.GLOBAL_ADMINISTRATION,
        PERMISSIONS.GLOBAL_REPORTS,
        PERMISSIONS.REPORTS,
    ],
    "/lock": [PERMISSIONS.LOCKING],
    MailModule: [PERMISSIONS.ADMINISTRATION, PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/mail": [PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/mail/templates": [PERMISSIONS.ADMINISTRATION, PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/locations": [PERMISSIONS.GLOBAL_ADMINISTRATION],
    "/templatesManager": [PERMISSIONS.GLOBAL_ADMINISTRATION],
};

export function assertUserPermissions(route, userPermissions = []) {
    const result = routePermissions[route].some((requiredPermission) => {
        return userPermissions[requiredPermission] === true;
    });

    return result;
}
