import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    dialog: {
        padding: theme.spacing(1),
    },
    message: {
        padding: theme.spacing(1),
        whiteSpace: "pre-line"
    }
});

class Info extends React.Component {
    render() {
        const { classes, type, message, open } = this.props;
        let messages;

        if (Array.isArray(message)) {
            messages = message.map((message, index) => {
                return {
                    id: index,
                    content: message
                }
            });
        } else {
            messages = [{ id: 0, content: message }];
        }

        return (
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open && !!message}
                onClose={this.handleClose}
                PaperProps={{
                    className: classes.dialog
                }}
            >
                <DialogTitle>
                    {type}
                </DialogTitle>
                <DialogContent>
                    {messages.map((message) => (
                        <Typography key={message.id} className={classes.message} align="justify">
                            {message.content}
                        </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={this.props.onClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Info);
