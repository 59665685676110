import React from "react";
import {getBuildInfo} from "../../../api/api";

class Version extends React.Component {
    constructor(props) {
        super(props);

        this.state = {version: process.env.REACT_APP_VERSION};
    }

    componentDidMount() {
        getBuildInfo().then(info => {
            this.setState({version: info.version});
        });
    }

    render() {
        const {version} = this.state;

        return (
            <div
                id="version"
                title={`v${version} (${process.env.NODE_ENV})`}
            >
                v{version}
            </div>
        );
    }
}

export default Version;
