import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { getFAQCategories, editFAQCategory, deleteFAQCategory } from "api/api";

import FAQ from "./FAQ";
import FAQSingleCategory from "./FAQSingleCategory";
import FAQSearchResult from "./FAQSearchResult";
import ProtectedFAQCategory from "./ProtectedFAQCategory";

function FAQModule() {
    const history = useHistory();
    const [categories, setCategories] = useState(undefined);
    const [fetchTrigger, setFetchTrigger] = useState(true);
    const [maxPriority, setMaxPriority] = useState(null);

    useEffect(() => {
        const fetchCategories = () => {
            getFAQCategories(true).then((data) => {
                setMaxPriority(data.length);
                setCategories(data);
                setFetchTrigger(false);
            });
        };

        if (fetchTrigger) {
            fetchCategories();
        }
    }, [fetchTrigger]);

    const editCategory = async (category) => {
        return editFAQCategory(category).then(() => {
            setFetchTrigger(true);
        });
    };

    const deleteCategory = async (removedCategory) => {
        return deleteFAQCategory(removedCategory.id).then(() => {
            setFetchTrigger(true);
            history.replace({ pathname: "/help" });
        });
    };

    return (
        <>
            <h2 className="custom-title">FAQ</h2>

            <Switch>
                <Route path="/help/category/:id">
                    <ProtectedFAQCategory categories={categories}>
                        <FAQSingleCategory
                            parentCategories={categories}
                            parentEditCategory={editCategory}
                            parentDeleteCategory={deleteCategory}
                            maxPriority={maxPriority}
                        />
                    </ProtectedFAQCategory>
                </Route>

                <Route path="/help/search">
                    <FAQSearchResult categories={categories} />
                </Route>

                <Route path="/help">
                    <FAQ
                        categories={categories}
                        setCategories={setCategories}
                        maxPriority={maxPriority}
                        setFetchTrigger={setFetchTrigger}
                    />
                </Route>
            </Switch>
        </>
    );
}

export default FAQModule;
