import React from "react";
import AutoSuggest from "./AutoSuggest";

export const STATE = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};

export const STATE_TO_LABEL = {
    [STATE.ACTIVE]: "Active",
    [STATE.INACTIVE]: "Inactive"
}

class TaskStateAutoSuggest extends React.Component {
    render() {
        return (
            <AutoSuggest
                label="State"
                fetchOptions={this.suggestState.bind(this)}
                renderOption={(state) => ({
                    value: state,
                    label: STATE_TO_LABEL[state]
                })}
                optionIdentifier={(value) => value}
                {...this.props}
            />
        );
    }

    suggestState(q) {
        if (q) {
            q = q.toLocaleLowerCase();
        } else {
            q = "";
        }

        return Promise.resolve(
            Object.keys(STATE_TO_LABEL)
                .filter(accessKey => (STATE_TO_LABEL[accessKey] || "").toLocaleLowerCase().indexOf(q) >= 0)
        );
    }
}

export default TaskStateAutoSuggest;
