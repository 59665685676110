import React from "react";
import AutoSuggest from "./AutoSuggest";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
//
import { ReactComponent as PresentIcon } from "../../../assets/icons/icon_office.svg";
import { ReactComponent as AbsentIcon } from "../../../assets/icons/icon_absent.svg";
import { ReactComponent as RemoteIcon } from "../../../assets/icons/icon_remote.svg";
import { ReactComponent as DelegationIcon } from "../../../assets/icons/icon_delegation.svg";
import { ReactComponent as VacationIcon } from "../../../assets/icons/icon_vacation.svg";
import { ReactComponent as HolidayIcon } from "../../../assets/icons/icon_holiday.svg";
import { ReactComponent as SickLeaveIcon } from "../../../assets/icons/icon_sickleave.svg";
//

export const PRESENCE = {
    PRESENT: "PRESENT",
    REMOTE: "REMOTE",
    DELEGATION: "DELEGATION",
    SICK_LEAVE: "SICK_LEAVE",
    VACATION: "VACATION",
    ABSENT: "ABSENT",
    HOLIDAY: "HOLIDAY",
    WEEKEND: "WEEKEND",
    UNSET: "UNSET",
};

export const PRESENCE_TO_LABEL = {
    [PRESENCE.PRESENT]: "Work - Office",
    [PRESENCE.REMOTE]: "Work - Remote",
    [PRESENCE.DELEGATION]: "Work - Business travel",
    [PRESENCE.VACATION]: "Absent - Vacation",
    [PRESENCE.SICK_LEAVE]: "Absent - Sick",
    [PRESENCE.ABSENT]: "Absent - Other",
    [PRESENCE.HOLIDAY]: "Public Holiday",
    [PRESENCE.WEEKEND]: "Weekend",
};

export const PRESENCE_TO_HINT = {
    [PRESENCE.PRESENT]: "Work in the office",
    [PRESENCE.REMOTE]: "Remote work from home",
    [PRESENCE.DELEGATION]: "Remote work during business travel",
    [PRESENCE.VACATION]: "Not working because of having vacation or annual leave",
    [PRESENCE.SICK_LEAVE]: "Not working because of illness",
    [PRESENCE.ABSENT]: "Not working because of other reasons",
};

export const PRESENCE_TO_ICON_COMPONENT = {
    [PRESENCE.PRESENT]: PresentIcon,
    [PRESENCE.ABSENT]: AbsentIcon,
    [PRESENCE.REMOTE]: RemoteIcon,
    [PRESENCE.DELEGATION]: DelegationIcon,
    [PRESENCE.VACATION]: VacationIcon,
    [PRESENCE.HOLIDAY]: HolidayIcon,
    [PRESENCE.SICK_LEAVE]: SickLeaveIcon,
};

export const MANAGE_PRESENCE = {
    UPDATE: "UPDATE",
    DO_NOT_CHANGE: "DO_NOT_CHANGE",
};

export const PRESENT_PRESENCES = [PRESENCE.PRESENT, PRESENCE.REMOTE, PRESENCE.DELEGATION];
export const ABSENT_PRESENCES = [PRESENCE.SICK_LEAVE, PRESENCE.VACATION, PRESENCE.ABSENT];

export const ABSENT_PROJECTS = ["QZ2300", "QZ2308", "INZ0", "MYZ0", "UKZ000"];

const HIDDEN_PRESENCES = [PRESENCE.WEEKEND, PRESENCE.HOLIDAY];

const SELECTABLE_PRESENCES = PRESENT_PRESENCES.concat(ABSENT_PRESENCES);

function OptionIcon({ Icon }) {
    return (
        <Box component="span" color="text.secondary" width={24} height={24}>
            <SvgIcon component={Icon} color="inherit" className="autoSuggest--icon" />
        </Box>
    );
}

class PresenceAutoSuggest extends React.Component {
    render() {
        return (
            <AutoSuggest
                label="Presence"
                fetchOptions={this.suggestPresence.bind(this)}
                disabled={HIDDEN_PRESENCES.indexOf(this.props.value) >= 0}
                renderOption={(presenceKey) => ({
                    value: presenceKey,
                    label: PRESENCE_TO_LABEL[presenceKey],
                    icon: <OptionIcon Icon={PRESENCE_TO_ICON_COMPONENT[presenceKey]} />,
                    hint: PRESENCE_TO_HINT[presenceKey],
                })}
                optionIdentifier={(value) => value}
                {...this.props}
            />
        );
    }

    suggestPresence(q) {
        const { hideAbsences, absent, isUop, isMark } = this.props;

        if (q) {
            q = q.toLocaleLowerCase();
        }

        if (isUop) {
            return Promise.resolve(
                (absent && !hideAbsences ? ABSENT_PRESENCES : PRESENT_PRESENCES).filter(
                    (presenceKey) =>
                        (PRESENCE_TO_LABEL[presenceKey] || "").toLocaleLowerCase().indexOf(q) >= 0
                )
            );
        } else {
            return Promise.resolve(
                (isMark ? SELECTABLE_PRESENCES : PRESENT_PRESENCES).filter(
                    (presenceKey) =>
                        (PRESENCE_TO_LABEL[presenceKey] || "").toLocaleLowerCase().indexOf(q) >= 0
                )
            );
        }
    }
}

export default PresenceAutoSuggest;
