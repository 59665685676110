import React from "react";
import AutoSuggest from "./AutoSuggest";

export const STATE = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const STATE_TO_LABEL = {
    [STATE.ACTIVE]: "Active",
    [STATE.INACTIVE]: "Inactive",
};

class UserStateAutoSuggest extends React.Component {
    render() {
        return (
            <AutoSuggest
                label={"State"}
                fetchOptions={this.suggestState.bind(this)}
                renderOption={(key) => ({label: `${STATE_TO_LABEL[key]}`, value: key})}
                optionIdentifier={(value) => value}
                {...this.props}
            />
        );
    }

    suggestState(q) {
        if (q) {
            q = q.toLocaleLowerCase();
        }

        return Promise.resolve(
            Object.keys(STATE_TO_LABEL)
                .filter(key => (STATE_TO_LABEL[key] || "").toLocaleLowerCase().indexOf(q) >= 0)
        )
    }
}

export default UserStateAutoSuggest;
