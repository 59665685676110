import React, { Fragment } from "react";
import ListItem from "@material-ui/core/ListItem";

export default function LoggedAs(props) {
    const user = props.user;
    return (
        <Fragment>
            <ListItem className="loggedas-info">
                {user && <span title={user.FullName}>Logged as: {user.FullName}</span>}
            </ListItem>
            <ListItem className="loggedas-info">
                {user && <span title={user.EmailAddress}>Email: {user.EmailAddress}</span>}
            </ListItem>
        </Fragment>
    );
}
