// IE11/stone age compatibility
import "core-js/stable";
import "regenerator-runtime/runtime";
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
//
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
    <HashRouter>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
        </MuiPickersUtilsProvider>
    </HashRouter>,
    document.getElementById("root")
);

// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
