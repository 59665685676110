import React from "react";

import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export default function MenuButton(props) {
    const { handler, icon, name } = props;

    return (
        <Link underline="none" onClick={handler || (() => {})}>
            <ListItem button>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        </Link>
    );
}
