import React from "react";
import CenteredView from "containers/centeredView/CenteredView";

class ErrorBoundary extends React.Component {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <CenteredView>
                    {this.props.message}
                </CenteredView>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
