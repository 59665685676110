import React, { useEffect } from "react";
import { updateLocation } from "api/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
        overflowY: "visible",
    },
    content: {
        overflowY: "visible",
    },
}));

const schema = yup
    .object({
        checkDailyHours: yup.boolean(),
        checkQuarterHours: yup.boolean(),
    })
    .required();

export default function CheckHoursDialog({ location, open, handleClose }) {
    const {
        handleSubmit,
        setValue,
        control,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            checkDailyHours: true,
            checkQuarterHours: true,
        },
        resolver: yupResolver(schema),
    });
    const classes = useStyles();

    useEffect(() => {
        if (location) {
            setValue("checkDailyHours", location.checkDailyHours);
            setValue("checkQuarterHours", location.checkQuarterHours);
        }
    }, [location]);

    const onSubmit = (data) => {
        updateLocation(location.id, {
            title: location.title,
            domain: location.domain,
            shortcut: location.shortcut,
            weekHours: location.weekHours,
            workingDays: location.workingDays,
            buttons: location.buttons,
            checkDailyHours: data.checkDailyHours,
            checkQuarterHours: data.checkQuarterHours,
        }).then(() => {
            handleSubmit();
            handleClose();
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>Checking hours - {location?.key}</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Controller
                                control={control}
                                name="checkDailyHours"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={onChange}
                                                    id="checkDailyHours"
                                                    color="primary"
                                                />
                                            }
                                            label={"Check daily hours"}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Controller
                                control={control}
                                name="checkQuarterHours"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={onChange}
                                                    id="checkQuarterHours"
                                                    color="primary"
                                                />
                                            }
                                            label={"Check quarterly hours"}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}>
                        submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
