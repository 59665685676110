import React, { useState, useEffect } from "react";
import { updateLocation } from "api/api";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(1),
        overflowY: "visible",
    },
    content: {
        overflowY: "visible",
    },
}));

function EditLocation({ location, open, handleClose, handleSubmit }) {
    const classes = useStyles();
    const [formDisabled, setFormDisabled] = useState(true);
    const [editedLocation, setEditedLocation] = useState({
        key: "",
        title: "",
        domain: "",
        shortcut: "",
    });

    useEffect(() => {
        setEditedLocation(location);
    }, [location]);

    const onChange = (event) => {
        const { id, value } = event.target;

        setEditedLocation((prevState) => {
            const newLoc = {
                ...prevState,
                [id]: value,
            };

            setFormDisabled(Object.keys(newLoc).some((key) => newLoc[key].length < 1));

            return newLoc;
        });
    };

    const onSubmit = (event) => {
        event.preventDefault();

        updateLocation(editedLocation.id, {
            title: editedLocation.title,
            domain: editedLocation.domain,
            shortcut: editedLocation.shortcut,
            weekHours: location.weekHours,
            workingDays: location.workingDays,
            buttons: location.buttons,
            checkDailyHours: location.checkDailyHours,
            checkQuarterHours: location.checkQuarterHours,
        }).then(() => {
            handleSubmit();
            handleClose();
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialog,
            }}>
            <DialogTitle>Edit Location - {editedLocation?.title}</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                id="key"
                                label="Key"
                                variant="outlined"
                                fullWidth
                                error={!editedLocation?.key}
                                value={editedLocation?.key}
                                onChange={onChange}
                                disabled
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="title"
                                label="Title"
                                variant="outlined"
                                fullWidth
                                error={!editedLocation?.title}
                                value={editedLocation?.title}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="domain"
                                label="Domain"
                                variant="outlined"
                                fullWidth
                                error={!editedLocation?.domain}
                                value={editedLocation?.domain}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="shortcut"
                                label="Shortcut"
                                variant="outlined"
                                fullWidth
                                error={!editedLocation?.shortcut}
                                value={editedLocation?.shortcut}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={formDisabled}>
                        submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default EditLocation;
