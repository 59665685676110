import React, { useEffect, useState } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";

import "./DayFilter.scss";

function DayFilter(props) {
    const {
        onExcludeHolidaysChange,
        excludeHolidays,
        onExcludeAbsencesChange,
        excludeAbsences,
        days,
        onDaysChange,
    } = props;
    const weekdays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    const [change, setChange] = useState(false);

    function createDayComponent(index, label) {
        const founded = days.some((element) => element === index + 1);
        return (
            <Button
                className={"dayButton"}
                variant={founded ? "contained" : "outlined"}
                color="primary"
                onClick={() => {
                    const dayArray = days;
                    if (founded) {
                        const idx = dayArray.indexOf(index + 1);
                        dayArray.splice(idx, 1);
                    } else {
                        dayArray.push(index + 1);
                        dayArray.sort((a, b) => a - b);
                    }
                    onDaysChange(dayArray);
                    setChange(!change);
                }}>
                {label}
            </Button>
        );
    }

    return (
        <React.Fragment>
            {!!onExcludeHolidaysChange ||
            !!excludeHolidays ||
            !!onExcludeAbsencesChange ||
            !!excludeAbsences ? (
                <>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={9}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}>
                        {weekdays.map((day, index) => {
                            return (
                                <Grid item key={day}>
                                    {createDayComponent(index, day)}
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={3}
                        justifyContent={isSmUp ? "flex-start" : "center"}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => onExcludeHolidaysChange(e.target.checked)}
                                        checked={excludeHolidays}
                                    />
                                }
                                label="Exclude holidays"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => onExcludeAbsencesChange(e.target.checked)}
                                        checked={excludeAbsences}
                                    />
                                }
                                label="Exclude absences"
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <FormHelperText>Working days</FormHelperText>
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}>
                        {weekdays.map((day, index) => {
                            return (
                                <Grid item key={day}>
                                    {createDayComponent(index, day)}
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </React.Fragment>
    );
}

export default DayFilter;
