import React from "react";
import MenuIcon from "@material-ui/icons/Menu";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import TTMSLogo from "containers/components/logo/TTMSLogo";

const drawerWidth = "240px";

const useStyles = makeStyles((theme) => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    brandContainerToolbar: {
        display: "flex",
        alignItems: "center",
    },
    brandLogoToolbar: {
        width: "40px",
        height: "40px",
        marginRight: "15px",
    },
    brandTitleToolbar: {
        display: "inline",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
    },
}));

function MobileToolbar({ toggleDrawer }) {
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <>
            {isSmUp ? null : (
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => toggleDrawer()}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Box className={classes.brandContainerToolbar}>
                            <TTMSLogo className={classes.brandLogoToolbar} />
                            <Typography
                                component="h1"
                                variant="h6"
                                noWrap
                                className={classes.brandTitleToolbar}>
                                {"TimeSheets"}
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
        </>
    );
}

export default MobileToolbar;
